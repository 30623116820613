export const UR_deltaT = [
	[100, 90, 82, 72, 65, 56, 48, 40, 31, 25, 15, 8, 0, 0, 0, 0, 0, 0, 0],
	[100, 91, 83, 74, 66, 58, 50, 41, 34, 26, 18, 10, 5, 0, 0, 0, 0, 0, 0],
	[100, 91, 84, 75, 68, 60, 52, 45, 37, 30, 22, 15, 7, 2, 0, 0, 0, 0, 0],
	[100, 92, 84, 76, 69, 62, 54, 47, 40, 32, 26, 18, 11, 5, 0, 0, 0, 0, 0],
	[100, 92, 85, 77, 70, 64, 56, 50, 42, 35, 29, 24, 15, 10, 3, 0, 0, 0, 0],
	[100, 93, 86, 78, 72, 65, 58, 51, 45, 38, 32, 26, 19, 13, 7, 2, 0, 0, 0],
	[100, 93, 86, 79, 73, 66, 60, 54, 47, 40, 35, 29, 23, 17, 10, 5, 0, 0, 0],
	[100, 93, 87, 79, 74, 67, 61, 55, 49, 43, 37, 31, 26, 20, 15, 9, 3, 0, 0],
	[100, 93, 87, 80, 75, 69, 63, 56, 51, 45, 40, 34, 29, 23, 18, 13, 7, 3, 0],
	[100, 94, 88, 81, 76, 70, 64, 59, 53, 47, 42, 36, 31, 26, 21, 16, 11, 6, 2],
	[
		100, 94, 88, 82, 76, 71, 65, 60, 54, 49, 44, 39, 34, 29, 24, 19, 15, 10,
		5,
	],
	[
		100, 95, 88, 83, 77, 72, 66, 61, 56, 51, 45, 41, 36, 31, 27, 22, 18, 13,
		8,
	],
	[
		100, 95, 89, 83, 78, 73, 68, 63, 57, 53, 47, 43, 38, 33, 29, 25, 20, 17,
		12,
	],
	[
		100, 95, 89, 84, 78, 74, 69, 64, 59, 54, 48, 45, 40, 36, 32, 27, 23, 19,
		15,
	],
	[
		100, 95, 90, 84, 79, 74, 70, 65, 60, 55, 50, 47, 42, 38, 34, 30, 26, 22,
		18,
	],
	[
		100, 95, 90, 84, 80, 75, 71, 66, 61, 57, 52, 48, 44, 40, 36, 32, 28, 24,
		20,
	],
	[
		100, 95, 90, 85, 81, 76, 71, 67, 63, 58, 53, 50, 46, 42, 38, 34, 30, 26,
		22,
	],
	[
		100, 95, 90, 85, 81, 77, 72, 68, 64, 60, 55, 51, 47, 43, 40, 36, 32, 28,
		25,
	],
	[
		100, 95, 90, 86, 82, 77, 73, 69, 65, 61, 56, 53, 49, 45, 42, 38, 34, 30,
		27,
	],
	[
		100, 95, 91, 86, 82, 78, 74, 70, 66, 61, 57, 54, 50, 46, 43, 39, 36, 32,
		28,
	],
	[
		100, 96, 91, 87, 83, 78, 74, 71, 66, 62, 58, 55, 51, 47, 45, 41, 39, 34,
		31,
	],
	[
		100, 96, 91, 87, 83, 79, 75, 71, 67, 63, 60, 56, 53, 49, 46, 42, 39, 36,
		33,
	],
	[
		100, 96, 92, 87, 83, 80, 76, 72, 68, 64, 61, 57, 54, 50, 47, 44, 40, 37,
		35,
	],
	[
		100, 96, 92, 87, 84, 80, 76, 72, 69, 66, 62, 59, 55, 52, 48, 45, 42, 39,
		37,
	],
	[
		100, 96, 92, 88, 84, 80, 77, 73, 70, 67, 63, 60, 56, 53, 50, 46, 44, 40,
		38,
	],
	[
		100, 96, 92, 88, 84, 80, 77, 74, 70, 67, 63, 61, 57, 54, 51, 47, 45, 42,
		39,
	],
	[
		100, 96, 92, 88, 85, 81, 78, 74, 71, 68, 64, 61, 58, 55, 52, 49, 46, 43,
		40,
	],
	[
		100, 96, 92, 88, 85, 81, 78, 74, 71, 69, 65, 62, 58, 56, 52, 50, 47, 44,
		42,
	],
	[
		100, 96, 93, 89, 85, 82, 78, 75, 72, 69, 65, 62, 59, 57, 54, 51, 48, 45,
		42,
	],
	[
		100, 96, 93, 89, 86, 82, 79, 75, 72, 69, 66, 63, 60, 58, 55, 52, 49, 46,
		44,
	],
	[
		100, 96, 93, 89, 86, 83, 79, 76, 73, 70, 67, 64, 61, 58, 55, 53, 50, 47,
		45,
	],
	[
		100, 96, 93, 89, 86, 83, 80, 77, 73, 70, 67, 64, 62, 59, 56, 53, 51, 48,
		46,
	],
	[
		100, 96, 93, 90, 86, 83, 80, 77, 74, 71, 68, 65, 62, 60, 57, 54, 52, 49,
		47,
	],
	[
		100, 96, 93, 90, 87, 83, 80, 77, 74, 71, 69, 65, 63, 60, 58, 55, 53, 50,
		47,
	],
	[
		100, 97, 93, 90, 87, 84, 81, 78, 75, 72, 69, 66, 64, 61, 58, 56, 53, 51,
		48,
	],
	[
		100, 97, 93, 90, 87, 84, 81, 78, 75, 72, 70, 67, 64, 62, 59, 57, 54, 52,
		49,
	],
	[
		100, 97, 93, 90, 87, 84, 81, 78, 76, 73, 70, 67, 65, 62, 60, 57, 55, 52,
		50,
	],
	[
		100, 97, 94, 90, 87, 84, 82, 79, 76, 73, 71, 68, 65, 63, 60, 58, 55, 53,
		51,
	],
	[
		100, 97, 94, 91, 88, 85, 82, 79, 76, 74, 71, 68, 66, 63, 61, 58, 56, 54,
		52,
	],
	[
		100, 97, 94, 91, 88, 85, 82, 79, 77, 74, 71, 69, 66, 64, 61, 59, 57, 54,
		52,
	],
	[
		100, 97, 94, 91, 88, 85, 82, 80, 77, 74, 72, 69, 67, 64, 62, 60, 57, 55,
		53,
	],
];
