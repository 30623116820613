import clockIcon from "../../../../../assets/icons/clock-icon.png";
import "../../panel-styles.css";

export default function IrrigationTimePanel({ data }) {
	let panelText = "...";

	if (data.ti !== null) {
		let irrigationTime = data.ti;

		// irrigationTime is in min, so:
		let hours = Math.floor(irrigationTime / 60);
		let minutes = Math.round(irrigationTime % 60);

		panelText = hours + "h" + minutes + "min";
	}

	return (
		<div
			className="alerts-container"
			style={{ width: "225px", height: "100px", marginBottom: "10px" }}
		>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">Tempo de Irrigação</span>
			</div>
			<div className="it-content-container">
				<img
					src={clockIcon}
					height={"55px"}
					alt=""
					style={{ margin: "0px 20px 0px 20px" }}
				/>

				<span className="it-text-style">{panelText}</span>
			</div>
		</div>
	);
}
