import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import PanelHeader from "../../panel-header";
import CultivationDataPanel from "../../panel/cultivation-data-panel";
import AlertsPanel from "../../panel/alerts-panel";
import HydrologicalBalancePanel from "../../panel/hydrological-balance-panel";
import WaterStoragePanel from "../../panel/water-storage-panel";
import HydrologicalBalanceTablePanel from "../../panel/hydrological-balance-table-panel";
import NoManagementZoneMessage from "../../messages-panel/no-management-zone-message";
import ErrorMessage from "../../messages-panel/error-message";

export default function HydrologicalBalanceData({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();

	let getZM = useGet(
		`/zona_manejo_consulta?zona_manejo_gateway=${gateway.id}`,
		access_token
	);

	const [zonaManejo, setZonaManejo] = useState(null);
	const [listaZonaManejo, setListaZOnaManejo] = useState([]);

	useEffect(() => {
		if (!getZM.state.loading && !getZM.state.error) {
			setListaZOnaManejo(getZM.state.data);
		}
	}, [getZM.state.loading, getZM.state.error, getZM.state.data]);

	return (
		<div className="dropdown-button-line">
			{getZM.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getZM.state.error ? (
				<ErrorMessage />
			) : listaZonaManejo.length > 0 ? (
				<div>
					<PanelHeader
						componentsList={listaZonaManejo}
						chosenComponent={zonaManejo}
						setChosenComponent={setZonaManejo}
						titleHeader={"Dados referentes à Zona de Manejo"}
						imageType={"management-zones"}
					/>
					{zonaManejo ? (
						<div>
							<div className="show-data-big-container">
								<CultivationDataPanel zonaManejo={zonaManejo} />
								<AlertsPanel zonaManejo={zonaManejo} />
							</div>

							<div className="show-data-container">
								<WaterStoragePanel zonaManejo={zonaManejo} />
								<HydrologicalBalancePanel
									zonaManejo={zonaManejo}
									gateway={gateway}
								/>
							</div>
							<div className="show-data-big-container">
								<HydrologicalBalanceTablePanel
									zonaManejo={zonaManejo}
									gateway={gateway}
								/>
							</div>
						</div>
					) : (
						<div
							style={{
								height: "5px",
							}}
						/>
					)}
				</div>
			) : (
				<NoManagementZoneMessage />
			)}
		</div>
	);
}
