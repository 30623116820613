import React from "react";

export const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
	const handleClick = () => {
		setActiveTab(id);
	};

	return (
		<li onClick={handleClick} className={activeTab === id ? "active" : ""}>
			{title}
		</li>
	);
};

export const TabContent = ({ id, activeTab, children }) => {
	return activeTab === id ? <div>{children}</div> : null;
};
