import React from "react";
import Plot from "react-plotly.js";
import moment from "moment";

export default function LineChart1ElementAndBarChart2Elements({
	data,
	name1,
	name2,
	name3,
	unit,
	color1,
	color2,
	color3,
	fileName,
}) {
	const values = [
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.PLUV.toFixed(1)),
			type: "bar",
			marker: { color: `${color1}` },
			name: name1,
			hoverinfo: "none",
		},
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.LA.toFixed(1)),
			type: "bar",
			marker: { color: `${color2}` },
			name: name2,
			hoverinfo: "none",
		},
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.ETC.toFixed(1)),
			mode: "line",
			marker: { color: `${color3}` },
			name: name3,
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY"
					)}<br><br>Pluv: ${item.PLUV}mm<br>LAplic.: ${
						item.LA
					}mm<br>ETc: ${item.ETC}mm`
			),
			hoverinfo: "text",
		},
	];

	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
					rangemode: "tozero",
				},
				xaxis: {
					tickformat: "%d/%m/%y",
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				barmode: "group",
				bargap: 0.25,
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 20, // Margem direita
					t: 15, // Margem superior
					b: 50, // Margem inferior
				},
				hoverlabel: {
					bgcolor: "rgba(51,51,51,255)", // Cor de fundo do tooltip
					font: { color: "white" }, // Cor do texto do tooltip
					align: "left",
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
				showlegend: true,
				legend: {
					orientation: "h",
					xanchor: "center",
					x: 0.5,
					y: 1.2,
				},
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "350px",
			}}
		/>
	);
}
