import React from "react";

export default function OneValuePanel(props) {
	return (
		<div
			className="alerts-container"
			style={{
				height: "85px",
				width: "170px",
				backgroundColor: `${props.panelColor}`,
			}}
		>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">{props.panelTitle}</span>
			</div>
			<div className="alerts-aw-panel-content">
				<span
					className="one-value-panel-text"
					style={{ color: `${props.textColor}` }}
				>
					{props.value}
					{props.unit}
				</span>
			</div>
		</div>
	);
}
