import React from "react";
import Plot from "react-plotly.js";

export default function BarChart1Element({ data, unit, color, fileName }) {
	const values = [
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.value),
			type: "bar",
			marker: { color: `${color}` },
			text: data.map((d) => d.value),
			textfont: {
				size: 10,
				color: color,
			},
			textposition: "outside",
			insidetextanchor: "start", // Inicia o texto fora da barra
			offset: 5, // Ajuste o valor conforme necessário
			hoverinfo: "x+text",
		},
	];

	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
					rangemode: "tozero",
				},
				xaxis: {
					tickformat: "%d/%m/%y",
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 20, // Margem direita
					t: 20, // Margem superior
					b: 50, // Margem inferior
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "350px",
			}}
		/>
	);
}

// import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme } from "victory";
// import moment from "moment";

// export default function BarChart1Element({ data, unit, color }) {
// 	return (
// 		<div>
// 			<VictoryChart
// 				height={250}
// 				theme={VictoryTheme.material}
// 				domainPadding={{ y: 10, x: 30 }}
// 				padding={{
// 					top: 20,
// 					bottom: 40,
// 					left: 45,
// 					right: 30,
// 				}}
// 			>
// 				<VictoryAxis
// 					style={{
// 						tickLabels: {
// 							fontSize: 10,
// 							angle: -20,
// 							padding: 10,
// 						},
// 					}}
// 					tickCount={5}
// 					tickFormat={(t) => moment(t).format("DD/MM")}
// 				/>

// 				<VictoryAxis
// 					dependentAxis
// 					label={`${unit}`}
// 					style={{
// 						axisLabel: { fontSize: 10, padding: 30 },
// 						tickLabels: { fontSize: 10, padding: 2 },
// 					}}
// 					tickFormat={(t) => t.toFixed(1)}
// 				/>

// 				<VictoryBar
// 					data={data}
// 					x="timestamp"
// 					y="value"
// 					labels={({ datum }) => datum.value.toFixed(1)}
// 					style={{
// 						data: { fill: `${color}` },
// 						labels: {
// 							fontWeight: "bold",
// 							fontSize: 10,
// 							fill: "#687981",
// 						},
// 					}}
// 					barRatio={1}
// 				/>
// 			</VictoryChart>
// 		</div>
// 	);
// }
