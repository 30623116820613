import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import _ from "lodash";
import ReactLoading from "react-loading";

import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import { LineChartNElements } from "../../charts";

import { BiDotsHorizontal } from "react-icons/bi";

import moment from "moment";

import "../panel-styles.css";

export default function TensiometerChartPanel({ sensorsList }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [exportData, setExportData] = useState([]);
	const [plotData, setplotData] = useState([]);
	let ten20, ten40, ten60;

	if (sensorsList) {
		sensorsList.forEach((s) => {
			if (s.tag.includes("20cm")) ten20 = s;
			else if (s.tag.includes("40cm")) ten40 = s;
			else if (s.tag.includes("60cm")) ten60 = s;
		});
	}

	let api_request20, api_request40, api_request60;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 5);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");

		if (ten20)
			api_request20 = `evento_consulta?tipo_mensagem=1&sensor=${ten20.id_dispositivo}&data:>=${lastWeek}`;
		if (ten40)
			api_request40 = `evento_consulta?tipo_mensagem=1&sensor=${ten40.id_dispositivo}&data:>=${lastWeek}`;
		if (ten60)
			api_request60 = `evento_consulta?tipo_mensagem=1&sensor=${ten60.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			if (ten20)
				api_request20 = `evento_consulta?tipo_mensagem=1&sensor=${ten20.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
			if (ten40)
				api_request40 = `evento_consulta?tipo_mensagem=1&sensor=${ten40.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
			if (ten60)
				api_request60 = `evento_consulta?tipo_mensagem=1&sensor=${ten60.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			if (ten20)
				api_request20 = `evento_consulta?tipo_mensagem=1&sensor=${ten20.id_dispositivo}&data:>=${data1}`;
			if (ten40)
				api_request40 = `evento_consulta?tipo_mensagem=1&sensor=${ten40.id_dispositivo}&data:>=${data1}`;
			if (ten60)
				api_request60 = `evento_consulta?tipo_mensagem=1&sensor=${ten60.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData20 = useGet(api_request20, access_token);
	let getData40 = useGet(api_request40, access_token);
	let getData60 = useGet(api_request60, access_token);

	useEffect(() => {
		let dataArrayToExport = [];
		let dataTen20 = [];
		let dataTen40 = [];
		let dataTen60 = [];

		// Na maioria dos sistemas em campo se tem, no mínimo, os tens de 20 e 40cm
		if (
			(!getData20.state.loading && !getData20.state.error) ||
			(!getData40.state.loading && !getData40.state.error) ||
			(!getData60.state.loading && !getData60.state.error)
		) {
			if (getData20.state.data.length > 0) {
				dataTen20 = getData20.state.data.map((d) => {
					return {
						data: moment
							.unix(d.timestamp / 1000)
							.format("YYYY-MM-DDTHH:mm"),
						ten20cm: +d.valor_sensor,
					};
				});
			}

			if (getData40.state.data.length > 0) {
				dataTen40 = getData40.state.data.map((d) => {
					return {
						data: moment
							.unix(d.timestamp / 1000)
							.format("YYYY-MM-DDTHH:mm"),
						ten40cm: +d.valor_sensor,
					};
				});
			}

			if (getData60.state.data.length > 0) {
				dataTen60 = getData60.state.data.map((d) => {
					return {
						data: moment
							.unix(d.timestamp / 1000)
							.format("YYYY-MM-DDTHH:mm"),
						ten60cm: +d.valor_sensor,
					};
				});
			}

			dataArrayToExport = _(dataTen20)
				.concat(dataTen40)
				.groupBy("data")
				.reject({ length: 0 })
				.map(_.spread(_.merge))
				.value();

			dataArrayToExport = _(dataArrayToExport)
				.concat(dataTen60)
				.groupBy("data")
				.reject({ length: 0 })
				.map(_.spread(_.merge))
				.value();

			dataArrayToExport = dataArrayToExport.sort(
				(d1, d2) =>
					new Date(d1.data).getTime() - new Date(d2.data).getTime()
			);

			setplotData(dataArrayToExport);

			dataArrayToExport = dataArrayToExport.map((objeto) => ({
				...objeto,
				data: moment(objeto.data).format("DD/MM/YY HH:mm"),
			}));

			setExportData(dataArrayToExport);
		}
	}, [
		getData20.state.loading,
		getData20.state.error,
		getData20.state.data,
		getData40.state.loading,
		getData40.state.error,
		getData40.state.data,
		getData60.state.loading,
		getData60.state.error,
		getData60.state.data,
	]);

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={true} />
	);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Tensiometria</em>
			</div>
			<div>
				{getData20.state.loading &&
				getData40.state.loading &&
				getData60.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData20.state.error ||
				  getData40.state.error ||
				  getData60.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "10px" }}>
						<div
							className="panel-items"
							style={{ marginTop: "15px" }}
						>
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Gráfico de Tensiometria
							</span>
						</div>

						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={true}
						/>

						{exportData.length > 0 ? (
							<div className="chart-container">
								<LineChartNElements
									ten20={ten20}
									ten40={ten40}
									ten60={ten60}
									data={plotData}
									unit={"kPa"}
									color1={"#2E9522"}
									color2={"#29A8F8"}
									color3={"#A5314A"}
									fileName={"grafico-tensiometria"}
								/>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}

						{exportData.length > 0 ? (
							<div className="panel-items">
								<BiDotsHorizontal />
								<span className="panel-items-title">
									Exportar dados do Gráfico
								</span>
								<div className="right-container">
									<Export
										onExport={() =>
											downloadCSV(
												exportData,
												`dados-tensiometros`
											)
										}
									/>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}
