import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";

export default function GatewayEdit() {
	const { state } = useLocation();

	const [tag, setTag] = useState(`${state.gatewayData.tag}`);
	const [organizacao, setOrganizacao] = useState(
		state.organizationsNameList.find(
			(d) => d.id === state.gatewayData.organi_concentra_id
		)
	);

	const [numeroSerie, setNumeroSerie] = useState(
		`${state.gatewayData.serie}`
	);
	const [status, setStatus] = useState(
		deviceStatusItems.find(
			(d) =>
				d.nome ===
				(state.gatewayData.status === "on" ? "Ativo" : "Inativo")
		)
	);
	const [checkbox, setCheckbox] = useState(
		`${state.gatewayData.previsao}` === "on" ? true : false
	);
	const [modeloGateway, setModeloGateway] = useState(
		`${state.gatewayData.modelo}`
	);
	const [firmware, setFirmware] = useState(`${state.gatewayData.firmware}`);
	const [hardware, setHardware] = useState(`${state.gatewayData.hardware}`);

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">GUID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="guid"
					value={`${state.gatewayData.GUID}`}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={`${state.gatewayData.id_dispositivo}`}
					readOnly
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Tag * </span>
				<input
					className="admin-input"
					type="text"
					name="tag"
					value={tag}
					onChange={(e) => setTag(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Número de Série *</span>
				<input
					className="admin-input"
					type="text"
					name="numeroserie"
					value={numeroSerie}
					onChange={(e) => setNumeroSerie(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Organização</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.organizationsNameList}
					selectedValue={organizacao}
					setSelectedValue={setOrganizacao}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Status</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={deviceStatusItems}
					selectedValue={status}
					setSelectedValue={setStatus}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<div className="checkbox-container">
					<input
						className="checkbox-style"
						type="checkbox"
						checked={checkbox}
						onChange={(e) => setCheckbox(e.target.checked)}
					/>
					<em className="checkbox-text">Fazer a Previsão do Tempo</em>
				</div>
			</div>

			<div className="admin-container">
				<span className="admin-title">Sobre o Concentrador</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Modelo do Dispositivo</em>
				<input
					className="admin-input"
					type="text"
					name="modelo"
					value={modeloGateway}
					onChange={(e) => setModeloGateway(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Versão do Firmware</em>
				<input
					className="admin-input"
					type="text"
					name="firmware"
					value={firmware}
					onChange={(e) => setFirmware(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Versão do Hardware</em>
				<input
					className="admin-input"
					type="text"
					name="hardware"
					value={hardware}
					onChange={(e) => setHardware(e.target.value)}
				/>
			</div>

			<div className="footer">
				<SaveDataButton
					data={{
						id: `${state.gatewayData.id}`,
						organi_concentra_id: organizacao
							? `${organizacao.id}`
							: null,
						id_dispositivo: `${state.gatewayData.id_dispositivo}`,
						tag: `${tag}`,
						serie: `${numeroSerie}`,
						modelo: `${modeloGateway}`,
						firmware: `${firmware}`,
						hardware: `${hardware}`,
						status: status.nome === "Ativo" ? "on" : null,
						previsao: `${checkbox ? "on" : null}`,
						GUID: `${state.gatewayData.GUID}`,
					}}
					action={"put"}
					endpoint={`/gateway_api/${state.gatewayData.id}`}
					returnTo={"/gateways"}
				/>
				<DeleteDataButton
					endpoint={`/gateway_api/${state.gatewayData.id}`}
					returnTo={"/gateways"}
				/>
			</div>
		</div>
	);
}
