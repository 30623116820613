import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import requestConfig from "../../reducers/request_config";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";

export default function SendPasswordButton(props) {
	const Navigate = useNavigate();
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);

	const [showAlert, setShowAlert] = useState(false);

	const handleSaveData = async (e) => {
		e.preventDefault();

		try {
			const resp = await axios.request(
				requestConfig({
					method: "put",
					endpoint: `/user_api/${props.userId}`,
					access_token: props.token,
					data: props.data,
				})
			);

			setResponse(resp);
			setShowAlert(true);
		} catch (e) {
			setErrorStatus(true);
			setError(e.message);
			setShowAlert(true);
		}

		setTimeout(() => {
			setShowAlert(false);
			Navigate(props.returnTo);
		}, 1000);
	};

	return (
		<div>
			<button
				className="send-data-button-custom"
				onClick={handleSaveData}
			>
				{props.text}
			</button>
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{response.status === 200 && showAlert ? (
				<AlertComponent
					message={"Senha salva com sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
		</div>
	);
}
