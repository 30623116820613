import { useState } from "react";
import { useLocation } from "react-router-dom";
import { generateRandomToken } from "../../../lib/generateRandomToken";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

export default function DataloggerCreate() {
	const { state } = useLocation();

	// Dados do Coletor
	const [idDispositivo, setIdDispositivo] = useState("");
	const [tag, setTag] = useState("");
	const [numeroSerie, setNumeroSerie] = useState("");
	const [organizacao, setOrganizacao] = useState("");
	const [gateway, setGateway] = useState("");
	const [status, setStatus] = useState(null);
	const [modeloDatalogger, setModeloDatalogger] = useState("");
	const [firmware, setFirware] = useState("");
	const [hardware, setHardware] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [altitude, setAltitude] = useState("");

	return (
		<div className="page">
			<div>
				<div className="admin-container">
					<span className="admin-title">ID *</span>
					<input
						className="admin-input"
						type="text"
						name="id"
						value={idDispositivo}
						onChange={(e) =>
							setIdDispositivo(e.target.value.toUpperCase())
						}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Tag *</span>
					<input
						className="admin-input"
						type="text"
						name="tag"
						value={tag}
						onChange={(e) => setTag(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<span className="admin-title">Número de Série *</span>
					<input
						className="admin-input"
						type="text"
						name="numeroserie"
						value={numeroSerie}
						onChange={(e) => setNumeroSerie(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Organização</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.list.organizationsNameList}
						selectedValue={organizacao}
						setSelectedValue={setOrganizacao}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Concentrador</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.list.gatewaysNameList}
						selectedValue={gateway}
						setSelectedValue={setGateway}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Status</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={deviceStatusItems}
						selectedValue={status}
						setSelectedValue={setStatus}
						width={"305px"}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Sobre o Coletor</span>
				</div>

				<div className="admin-container">
					<em className="item-text">Modelo</em>
					<input
						className="admin-input"
						type="text"
						name="modelo"
						value={modeloDatalogger}
						onChange={(e) => setModeloDatalogger(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Versão do Firmware</em>
					<input
						className="admin-input"
						type="text"
						name="firmware"
						value={firmware}
						onChange={(e) => setFirware(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<em className="item-text">Versão do Hardware</em>
					<input
						className="admin-input"
						type="text"
						name="hardware"
						value={hardware}
						onChange={(e) => setHardware(e.target.value)}
					/>
				</div>

				<div className="admin-container">
					<span className="admin-title">Geolocalização</span>
				</div>
				<div className="admin-container">
					<em className="item-text">Latitude</em>
					<input
						className="admin-input"
						type="text"
						name="latitude"
						value={latitude}
						onChange={(e) => setLatitude(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<em className="item-text">Longitude</em>
					<input
						className="admin-input"
						type="text"
						name="longitude"
						value={longitude}
						onChange={(e) => setLongitude(e.target.value)}
					/>
				</div>
				<div className="admin-container">
					<em className="item-text">Altitude (m)</em>
					<input
						className="admin-input"
						type="text"
						name="altitude"
						value={altitude}
						onChange={(e) => setAltitude(e.target.value)}
					/>
				</div>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						organi_coletor_id: organizacao ? organizacao.id : null,
						id_dispositivo: idDispositivo,
						guid_dispositivo: `${generateRandomToken(
							8
						)}-${generateRandomToken(4)}-${generateRandomToken(
							4
						)}-${generateRandomToken(4)}-${generateRandomToken(
							12
						)}`,
						tag: tag,
						serie: +numeroSerie,
						modelo: modeloDatalogger,
						status: status && status.nome === "Ativo" ? "on" : null,
						concentrador: gateway ? gateway.id : "",
						firmware: firmware,
						hardware: hardware,
						lat: latitude,
						lon: longitude,
						elev: altitude,
					}}
					action={"post"}
					endpoint={"/coletor_api"}
					returnTo={"/dataloggers"}
				/>
			</div>
		</div>
	);
}
