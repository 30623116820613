import React from "react";

import PulverizationConditionsPanel from "../../panel/pulverization-conditions-panel";
import DeltaTPanel from "../../panel/delta-t-panel";

export default function PulverizationData({ gateway }) {
	return (
		<div className="dropdown-button-line">
			<div className="show-data-container">
				<PulverizationConditionsPanel gateway={gateway} />
				<DeltaTPanel gateway={gateway} />
			</div>
		</div>
	);
}
