import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../services/api";
import AuthService from "../../services/auth";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";
import { MdOutlineDeleteForever } from "react-icons/md";

export default function DeleteItemButton({ endpoint, returnTo }) {
	const Navigate = useNavigate();
	const user = AuthService.getCurrentUser();
	const [isLoading, setIsloading] = useState(false);
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const handleDeleteItemData = async (e) => {
		e.preventDefault();
		setIsloading(true);
		let config = {
			method: "delete",
			url: `${BASE_URL}${endpoint}`,
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		};
		try {
			const resp = await axios.request(config);
			setResponse(resp);
		} catch (e) {
			setErrorStatus(true);
			setError(e.message);
			setShowAlert(true);
		} finally {
			setIsloading(false);
		}
		setTimeout(() => {
			setShowAlert(false);
			Navigate(returnTo);
		}, 2000);
	};

	return (
		<div>
			<button
				className="data-table-delete-item-button-custom"
				onClick={handleDeleteItemData}
			>
				<MdOutlineDeleteForever size={"16px"} className="icon-style" />
				Deletar
			</button>
			{isLoading ? (
				<AlertComponent message={"Carregando..."} code={"LOADING"} />
			) : null}
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{response.status === 200 ? (
				<AlertComponent
					message={"Item removido com sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
		</div>
	);
}
