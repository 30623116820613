import { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth';

import { useGet } from '../../../reducers/useGet';

import DataTable from 'react-data-table-component';
import AddButton from '../../../components/data-table-custom/add-button';
import SearchBar from '../../../components/data-table-custom/search-bar';
import ExportButton from '../../../components/data-table-custom/export-button';
import downloadCSV from '../../../scripts/export-script';
import { paginationComponentOptions } from '../../../components/data-table-custom/dataTableCustom';
import ErrorMessage from '../../dashboard/messages-panel/error-message';

export default function GatewayList() {
	const { nivel, organizacao, access_token } = AuthService.getCurrentUser();
	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);

	let gtwRequest, orgRequest;
	switch (nivel) {
		case 2:
			orgRequest = `organizacao_consulta?organi_representante=${organizacao}`;
			gtwRequest = `gateway_consulta?gateway_representante=${organizacao}`;
			break;
		case 3:
			orgRequest = 'organizacao_api';
			gtwRequest = 'gateway_api';
			break;
		default:
			orgRequest = `organizacao_consulta?organi_id=${organizacao}`;
			gtwRequest = `gateway_consulta?gateway_organi=${organizacao}`;
			break;
	}

	let gatewaysList = useGet(gtwRequest, access_token);
	let organizationsList = useGet(orgRequest, access_token);

	useEffect(() => {
		if (
			!gatewaysList.state.loading &&
			!gatewaysList.state.error &&
			!organizationsList.state.loading &&
			!organizationsList.state.error
		) {
			const enrichedData = gatewaysList.state.data.map((gateway) => {
				const organization = organizationsList.state.data.find(
					(org) => org.id === gateway.organi_concentra_id
				);
				console.log(gateway);
				return {
					...gateway,
					organization: organization ? organization.nome : '',
				};
			});
			setData(enrichedData);
			setRecords(enrichedData);

			setOrganizationsNameList(organizationsList.state.data);
		}
	}, [
		gatewaysList.state.loading,
		gatewaysList.state.data,
		gatewaysList.state.error,
		organizationsList.state.loading,
		organizationsList.state.data,
		organizationsList.state.error,
	]);

	const columns = [
		{ name: 'id', selector: (row) => row.id, sortable: true },
		{
			name: 'ID',
			selector: (row) => row.id_dispositivo,
			sortable: true,
		},
		{ name: 'Tag', selector: (row) => row.tag, sortable: true },
		{
			name: 'Número de Série',
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: 'Organização',
			selector: (row) => {
				let org = organizationsList.state.data.find(
					(el) => el.id === row.organi_concentra_id
				);
				if (org === undefined) return '';
				else return org.nome;
			},
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => (row.status === 'on' ? 'Ativo' : 'Inativo'),
			sortable: true,
		},
	];

	const openGatewayDetail = (gatewayData) => {
		Navigate(`/gateways/${gatewayData.id}`, {
			state: {
				gatewayData,
				organizationsNameList,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{gatewaysList.state.loading && organizationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : gatewaysList.state.error || organizationsList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar table={'gateways'} setRecords={setRecords} data={data} />

						<Export onExport={() => downloadCSV(data, 'concentradores')} />
						{nivel === 3 ? (
							<AddButton
								goTo={'/gateways/add-new-gateway'}
								list={organizationsNameList}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Concentrador encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(gatewayData) =>
							nivel === 3 ? openGatewayDetail(gatewayData) : null
						}
					/>
				</div>
			)}
		</div>
	);
}
