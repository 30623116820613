import React from "react";

import SelectDropdown from "../../select-dropdown/selectDropdown";

export default function DashboardHeader({
	item,
	setItem,
	itemsList,
	headerText,
}) {
	return (
		<div className="header-dashboard">
			<div className="header-dashboard-item">
				<p className="header-dashboard-text">{headerText}</p>
			</div>

			<div
				className="header-dashboard-item"
				style={{ margin: "-10px 0px 0px 0px" }}
			>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={itemsList}
					selectedValue={item}
					setSelectedValue={setItem}
					width={"200px"}
				/>
			</div>
		</div>
	);
}
