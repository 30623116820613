import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import AuthService from "../../../../services/auth";
import { BASE_URL } from "../../../../services/api";

export default function TankLevelPanel({ devices }) {
  const { access_token } = AuthService.getCurrentUser();
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Estado para armazenar o erro

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(devices[0].sensorsList.tag);
        const requests = devices.map((device) => {
          const api_request = `${BASE_URL}/evento_consulta?tipo_mensagem=1&sensor=${device.sensorsList.id_dispositivo}&ultimo_dado=1`;
          console.log(api_request);
          return fetch(api_request, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }).then((response) => response.json());
        });

        const responses = await Promise.all(requests);
        console.log(responses);

        const formattedData = responses.map((item, index) => {
          console.log(
            "Item response:",
            devices[index].sensorsList.modelo.superior
          ); // Adiciona um console.log aqui para verificar o conteúdo de item
          return {
            caixas: devices[index].sensorsList.tag, // Substitua por como você quer identificar o dispositivo
            total: devices[index].sensorsList.modelo.superior, // Substitua pelos campos corretos
            atual:
              item.length === 0
                ? item.valor_sensor || 0
                : item[0].valor_sensor || 0, // Substitua pelos campos corretos
          };
        });

        setAllData(formattedData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(error); // Define o erro no estado
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [devices, access_token]);

  const getStatusIcon = (total, atual, size = 24) => {
    const percentage = (atual / total) * 100;

    if (percentage >= 100) {
      return <FaTimesCircle color="red" size={size} />;
    } else if (percentage >= 75) {
      return <FaExclamationCircle color="orange" size={size} />;
    } else {
      return <FaCheckCircle color="green" size={size} />;
    }
  };

  const columns = [
    {
      name: "Caixas",
      selector: (row) => row.caixas,
      sortable: true,
      wrap: true,
    },
    {
      name: "%",
      cell: (row) => getStatusIcon(row.total, row.atual),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { name: "Total", selector: (row) => row.total, sortable: true },
    { name: "Atual", selector: (row) => row.atual, sortable: true },
  ];

  if (loading) {
    return (
      <div
        className="big-panel-container"
        style={{ width: "100%", minWidth: "300px" }}
      >
        <div className="panel-title-container">
          <em className="panel-title">Nível de Reservatório</em>
        </div>
        <div style={{ margin: "10px" }}>
          <div style={{ margin: "0px 20px 0px 20px" }}>
            <div className="loading-container">
              <ReactLoading
                type="bubbles"
                color="var(--main-color)"
                width="50px"
                height="50px"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="big-panel-container"
        style={{ width: "100%", minWidth: "300px" }}
      >
        <div className="panel-title-container">
          <em className="panel-title">Nível de Reservatório</em>
        </div>
        <div style={{ margin: "10px" }}>
          <div style={{ margin: "0px 20px 0px 20px" }}>
            <div className="error-container">
              <p style={{ color: "red", textAlign: "center" }}>
                Ocorreu um erro ao carregar os dados.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="big-panel-container"
      style={{ width: "100%", minWidth: "300px" }}
    >
      <div className="panel-title-container">
        <em className="panel-title">Nível de Reservatório</em>
      </div>
      <div style={{ margin: "10px" }}>
        <div style={{ margin: "0px 20px 0px 20px" }}>
          <DataTable
            columns={columns}
            data={allData}
            noDataComponent="Nenhum dado encontrado."
            fixedHeader
            pagination
            striped
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationDefaultPage={1}
            paginationPerPage={5}
          />
        </div>
      </div>
    </div>
  );
}
