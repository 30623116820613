import React, { useState, useEffect } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import moment from "moment/moment";

import AvailableWaterPanel from "./available-water-panel";
import OneValuePanel from "./one-value-panel";
import IrrigationTimePanel from "./irrigation-time-panel";
import PercentimeterPanel from "./percentimeter-panel/percentimeter-panel";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import "../panel-styles.css";

export default function AlertsPanel({ zonaManejo }) {
	const { access_token } = AuthService.getCurrentUser();
	const [data, setData] = useState([]);
	const [irrigationType, setIrrigationType] = useState("");

	let currentAvailableWater20 = "..."; // %
	let currentAvailableWater60 = "..."; // %
	let currentLaminaBruta = "..."; // mm
	let colorAW20Panel = "gray",
		colorAW60Panel = "gray";
	let percentageAvailableWater20 = 0; // %
	let percentageAvailableWater60 = 0; // %

	// Panels color
	let blue = "rgb(68, 114, 196)";
	let yellow = "rgb(196, 196, 69)";
	let red = "rgb(206, 100, 100)";

	let getIrrigationType = useGet(
		`irriga_api/${zonaManejo.dado_irriga}`,
		access_token
	);

	let getZMData = useGet(
		`dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&ultimo_dado=1`,
		access_token
	);

	useEffect(() => {
		if (
			!getIrrigationType.state.loading &&
			!getIrrigationType.state.error &&
			!getZMData.state.loading &&
			!getIrrigationType.state.error &&
			getZMData.state.data.length > 0
		) {
			setIrrigationType(getIrrigationType.state.data.nome);
			setData(getZMData.state.data[0]);
		}
	}, [
		getIrrigationType.state.loading,
		getIrrigationType.state.error,
		getIrrigationType.state.data,
		getZMData.state.loading,
		getZMData.state.error,
		getZMData.state.data,
	]);

	if (data.lenght !== 0) {
		if (
			data.arm20 !== undefined &&
			data.arm20 !== null &&
			data.ad20 !== undefined &&
			data.ad20 !== null
		) {
			currentAvailableWater20 = data.arm20.toFixed(1);
			percentageAvailableWater20 = data.ad20;
			if (percentageAvailableWater20 >= zonaManejo.tensao_otima_20)
				colorAW20Panel = blue;
			else if (percentageAvailableWater20 < zonaManejo.tensao_critica_20)
				colorAW20Panel = red;
			else colorAW20Panel = yellow;
		}

		if (
			data.arm !== undefined &&
			data.arm !== null &&
			data.ad60 !== undefined &&
			data.ad60 !== null
		) {
			currentAvailableWater60 = data.arm.toFixed(1);
			percentageAvailableWater60 = data.ad60;

			if (percentageAvailableWater60 >= zonaManejo.tensao_otima_60)
				colorAW60Panel = blue;
			else if (percentageAvailableWater60 < zonaManejo.tensao_critica_60)
				colorAW60Panel = red;
			else colorAW60Panel = yellow;
		}

		if (data.lb !== undefined && data.lb !== null)
			currentLaminaBruta = data.lb;
	}

	return (
		<div className="alerts-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Alertas</em>
			</div>

			{getIrrigationType.state.loading || getZMData.state.loading ? (
				<div className="panel-loading">
					<ReactLoading
						type="spinningBubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getIrrigationType.state.error || getZMData.state.error ? (
				<ErrorMessage />
			) : data.arm20 && data.arm && data.ad20 && data.ad60 ? (
				<div className="alerts-panel-content-container">
					<div className="alerts-panel-available-water-content-container">
						<AvailableWaterPanel
							colorAW20Panel={colorAW20Panel}
							currentAvailableWater20={currentAvailableWater20}
							percentageAvailableWater20={
								percentageAvailableWater20
							}
							colorAW60Panel={colorAW60Panel}
							currentAvailableWater60={currentAvailableWater60}
							percentageAvailableWater60={
								percentageAvailableWater60
							}
						/>
						<div className="alerts-aw-lb-panel-div">
							<OneValuePanel
								panelColor={colorAW20Panel}
								textColor={"white"}
								value={percentageAvailableWater20}
								panelTitle={"Água Disponível em até 20cm"}
								unit={"%"}
							/>
							<OneValuePanel
								panelColor={colorAW60Panel}
								textColor={"white"}
								value={percentageAvailableWater60}
								panelTitle={"Água Disponível em até 60cm"}
								unit={"%"}
							/>
							<OneValuePanel
								panelColor={"white"}
								textColor={"black"}
								value={currentLaminaBruta}
								panelTitle={"Lâmina Bruta"}
								unit={"mm"}
							/>
						</div>
					</div>
					{irrigationType ? (
						<div className="alerts-aw-panel-content">
							{irrigationType.includes("Gotejamento") ||
							irrigationType.includes("Aspersão") ? (
								<IrrigationTimePanel data={data} />
							) : null}
							{irrigationType.includes("Pivô") ? (
								<PercentimeterPanel data={data} />
							) : null}
						</div>
					) : null}
					<div className="panel-footer-container">
						<p className="panel-footer-text">
							Dados atualizados em{" "}
							{moment(data.data).format("DD/MM/YYYY")}
						</p>
					</div>
				</div>
			) : (
				<NoDataAvailableMessage />
			)}
		</div>
	);
}
