import React from "react";

import THDataAnimalPanel from "../../panel/th-data-animal-panel";
import CarbonDioxideDataPanel from "../../panel/carbon-dioxide-data-panel";
import AmmoniaGasDataPanel from "../../panel/ammonia-gas-panel";
import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import pinLocationIcon from "../../../../assets/icons/pin-location-icon.png";
import { tempSensor, co2Sensor, nh3Sensor } from "../../../../sensors-type";

export default function WellBeingData({
	dataloggersList,
	sensors,
	sensorsType,
}) {
	let stationsDevices = {};

	dataloggersList.forEach((element) => {
		let getStationsNumber = element.tag.substring(6, 7);
		let stationID = "Estação " + getStationsNumber;

		// Buscar sensores conectados a este DL
		let getSensors = sensors.filter((s) => element.id === s.coletor);

		if (!stationsDevices[stationID]) {
			// Armazenar sensores por estação
			stationsDevices[stationID] = {
				sensors: [],
			};
		}

		// Tipos de Sensores [flag]
		let TH = false;
		let co2 = false;
		let nh3 = false; // gás amônia

		getSensors.forEach((s) => {
			let type = sensorsType.filter((el) => s.tipo === el.id);

			if (type.length > 0) {
				s.tipo = type[0].nome;
			}

			if (s.tipo === tempSensor) TH = true;
			else if (s.tipo === co2Sensor) co2 = true;
			else if (s.tipo === nh3Sensor) nh3 = true;
		});

		stationsDevices[stationID].sensors.push(getSensors);
		stationsDevices[stationID].sensorsType = [
			{ TH: TH, CO2: co2, NH3: nh3 },
		];
	});

	return (
		<div className="dropdown-button-line">
			{dataloggersList.length === 0 || sensors.length === 0 ? (
				<NoDeviceConnectedMessage />
			) : null}
			<div>
				{Object.keys(stationsDevices).map((item) => {
					const devicesConnected = stationsDevices[item];
					return (
						<div key={item}>
							<div className="station-title">
								<img
									src={pinLocationIcon}
									height={"25px"}
									alt="location-pin-icon"
									style={{ marginRight: "10px" }}
								/>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "16px",
									}}
								>
									{item}
								</span>
							</div>
							{devicesConnected.sensorsType.map((type) => {
								return (
									<div key={item}>
										{!type.TH && !type.CO2 && !type.NH3 ? (
											<NoDeviceConnectedMessage />
										) : (
											<div className="show-data-container">
												{type.TH ? (
													<THDataAnimalPanel
														sensors={
															devicesConnected
																.sensors[0]
														}
													/>
												) : null}
												{type.CO2 ? (
													<CarbonDioxideDataPanel
														sensors={
															devicesConnected
																.sensors[0]
														}
													/>
												) : null}
												{type.NH3 ? (
													<AmmoniaGasDataPanel
														sensors={
															devicesConnected
																.sensors[0]
														}
													/>
												) : null}
											</div>
										)}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
}
