import React from "react";
import Plot from "react-plotly.js";
import moment from "moment";

export default function LineChart2Elements2Axis({
  data1,
  data2,
  name1,
  name2,
  unit1,
  unit2,
  color1,
  color2,
  fileName,
}) {
  const values = [
    {
      x: data1.map((item) => item.timestamp),
      y: data1.map((item) => item.value),
      name: `${name1}`,
      mode: "lines",
      line: { color: `${color1}` },
      text: data1.map(
        (item, index) =>
          `${moment(item.timestamp)
            .utcOffset(-6)
            .format("DD/MM/YY HH:mm")}<br><br>Temp: ${item.value}ºC<br>UR: ${
            data2[index]?.value ?? ""
          }%`
      ),
      hoverinfo: "text",
    },
    {
      x: data2.map((item) => item.timestamp),
      y: data2.map((item) => item.value),
      name: `${name2}`,
      yaxis: "y2",
      mode: "lines",
      line: { color: `${color2}` },
      text: data2.map(
        (item, index) =>
          `${moment(item.timestamp)
            .utcOffset(-6)
            .format("DD/MM/YY HH:mm")}<br><br>Temp: ${
            data1[index]?.value ?? ""
          }ºC<br>UR: ${item.value}%`
      ),
      hoverinfo: "text",
    },
  ];

  return (
    <Plot
      data={values}
      layout={{
        yaxis: {
          title: {
            text: `${unit1}`,
            font: {
              size: 10,
              color: "rgba(83,107,123,255)",
            },
          },
          fixedrange: true,
          tickfont: {
            size: 10,
            color: "rgba(83,107,123,255)",
          },
          showline: true,
          linecolor: "rgba(144,164,174,255)",
          linewidth: 2,
        },
        yaxis2: {
          title: {
            text: `${unit2}`,
            font: {
              size: 10,
              color: "rgba(83,107,123,255)",
            },
          },
          overlaying: "y",
          side: "right",
          fixedrange: true,
          tickfont: {
            size: 10,
            color: "rgba(83,107,123,255)",
          },
          showline: true,
          linecolor: "rgba(144,164,174,255)",
          linewidth: 2,
        },
        xaxis: {
          tickformat: "%d/%m/%y <br> %H:%M",
          tickfont: {
            size: 9,
            color: "rgba(83,107,123,255)",
          },
          showline: true,
          linecolor: "rgba(144,164,174,255)",
          linewidth: 2,
        },
        dragmode: false, // Desabilita o pan
        zoommode: false, // Desabilita o zoom
        autosize: true,
        margin: {
          l: 30, // Margem esquerda
          r: 30, // Margem direita
          t: 40, // Margem superior
          b: 40, // Margem inferior
        },
        hoverlabel: {
          bgcolor: "rgba(51,51,51,255)", // Cor de fundo do tooltip
          font: { color: "white" }, // Cor do texto do tooltip
          align: "left",
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgb(250, 249, 249)",
        showlegend: true,
        legend: {
          orientation: "h",
          xanchor: "center",
          x: 0.5,
          y: 1.2,
        },
      }}
      config={{
        modeBarButtonsToRemove: [
          "pan2d",
          "select2d",
          "lasso2d",
          "resetScale2d",
          "zoomOut2d",
          "zoom2d",
          "zoomIn2d",
          "autoScale2d",
        ],
        displaylogo: false,
        toImageButtonOptions: {
          filename: fileName,
          format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
        },
      }}
      style={{
        width: "100%",
        height: "350px",
      }}
    />
  );
}

// export default function LineChart2Elements2Axis({
// 	data,
// 	name1,
// 	name2,
// 	unit1,
// 	unit2,
// 	color1,
// 	color2,
// }) {
// 	//Some chart properties
// 	const xOffsets = [50, 300];
// 	const tickPadding = [-5, -20];
// 	const anchors = ["end", "start"];
// 	const colors = [`${color1}`, `${color2}`];
// 	const labels = [`${unit1}`, `${unit2}`];

// 	// find maxima for normalizing data
// 	const maximumValue = data.map((dataset) =>
// 		Math.max(...dataset.map((d) => d.value))
// 	);

// 	return (
// 		<div>
// 			<VictoryChart
// 				height={250}
// 				theme={VictoryTheme.material}
// 				domainPadding={{ y: 15 }}
// 				padding={{
// 					top: 30,
// 					bottom: 40,
// 					left: 50,
// 					right: 50,
// 				}}
// 			>
// 				<VictoryAxis
// 					style={{
// 						tickLabels: { fontSize: 10 },
// 					}}
// 					tickFormat={(t) =>
// 						moment(t).utcOffset(-6).format("DD/MM HH:mm")
// 					}
// 					tickCount={4}
// 				/>

// 				{data.map((d, i) => (
// 					<VictoryAxis
// 						dependentAxis
// 						key={i}
// 						offsetX={xOffsets[i]}
// 						style={{
// 							axis: { stroke: "#90A4AE" },
// 							ticks: { padding: tickPadding[i] },
// 							tickLabels: {
// 								fill: "#465B65",
// 								textAnchor: anchors[i],
// 								fontSize: 10,
// 							},
// 						}}
// 						// Use normalized tickValues (0 - 1)
// 						tickValues={[0.25, 0.5, 0.75, 1]}
// 						// Re-scale ticks by multiplying by correct maxima
// 						tickFormat={(t) =>
// 							t * maximumValue[i].toFixed(0) + labels[i]
// 						}
// 					/>
// 				))}

// 				{data.map((d, i) => (
// 					<VictoryLine
// 						key={i}
// 						data={d}
// 						style={{ data: { stroke: colors[i] } }}
// 						interpolation="monotoneX"
// 						// normalize data
// 						y={(datum) => datum.value / maximumValue[i]}
// 						x="timestamp"
// 					/>
// 				))}

// 				<VictoryLegend
// 					x={60}
// 					orientation="horizontal"
// 					style={{ border: { stroke: "transparent" } }}
// 					colorScale={[`${color1}`, `${color2}`]}
// 					data={[{ name: `${name1}` }, { name: `${name2}` }]}
// 				/>
// 			</VictoryChart>
// 		</div>
// 	);
// }
