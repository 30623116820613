import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import SelectDropdown from "../../select-dropdown";
import { roleItems, statusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";

export default function UserEdit() {
	const { state } = useLocation();
	let { access_token, nivel } = AuthService.getCurrentUser();

	const userGET = useGet(`user_api/${state.userData.id}`, access_token);

	const [nome, setNome] = useState(`${state.userData.nome}`);
	const [status, setStatus] = useState(
		statusItems.find(
			(d) =>
				d.nome ===
				(state.userData.status === "on" ? "Habilitado" : "Desabilitado")
		)
	);
	const [papel, setPapel] = useState(
		roleItems.find((d) => d.nome === state.userData.nivel)
	);
	const [organizacao, setOrganizacao] = useState(
		nivel === 3
			? state.organizationsNameList.find(
					(d) => d.id === state.userData.organi_user_id
			  )
			: state.userData.organizacao
	);
	const [checkbox1, setCheckbox1] = useState(
		`${state.userData.notifica_inatividade}` === "on" ? true : false
	); // Notificação por Inatividade
	const [checkbox2, setCheckbox2] = useState(
		`${state.userData.valores_fora}` === "on" ? true : false
	); // Notificação de alertas de valores fora do normal
	const [checkbox3, setCheckbox3] = useState(
		`${state.userData.alerta_valores}` === "on" ? true : false
	); // Notificação de alarmes de valores críticos
	// const [atualizacaoAutomatica, setAtualizacaoAutomatica] = useState(
	// 	`${state.userData.intervalo_auto}`
	// );
	const [senha, setSenha] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordField, setNewPasswordField] = useState(false);

	useEffect(() => {
		if (!userGET.state.loading) {
			let userInfo = userGET.state.data;
			setSenha(`${userInfo.senha}`);

			if (nivel !== 3) {
				setNome(userInfo.nome);
				setStatus(
					statusItems.find(
						(d) =>
							d.nome ===
							(userInfo.status === "on"
								? "Habilitado"
								: "Desabilitado")
					)
				);

				setCheckbox1(
					`${userInfo.notifica_inatividade}` === "on" ? true : false
				);
				setCheckbox2(
					`${userInfo.valores_fora}` === "on" ? true : false
				);
				setCheckbox3(
					`${userInfo.alerta_valores}` === "on" ? true : false
				);
			}
		}
	}, [nivel, userGET.state.loading, userGET.state.data]);

	return (
		<div className="page">
			{userGET.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div>
					<div className="admin-container">
						<span className="admin-title">ID</span>
						<input
							className="admin-input-disabled"
							type="text"
							name="id"
							value={`${state.userData.id}`}
							readOnly
						/>
					</div>
					<div className="admin-container">
						<span className="admin-title">E-mail</span>
						<input
							className="admin-input"
							type="text"
							name="email"
							value={`${state.userData.email}`}
							disabled
						/>
					</div>
					<div className="admin-container">
						<span className="admin-title">Nome *</span>
						<input
							className="admin-input"
							type="text"
							name="nome"
							value={nome}
							onChange={(e) => setNome(e.target.value)}
						/>
					</div>
					{nivel === 3 ? (
						<div className="admin-container">
							<span className="admin-title">Status</span>
							<SelectDropdown
								placeHolder="Selecionar..."
								options={statusItems}
								selectedValue={status}
								setSelectedValue={setStatus}
								width={"305px"}
							/>
						</div>
					) : null}
					{nivel === 3 ? (
						<div className="admin-container">
							<span className="admin-title">Papel</span>
							<SelectDropdown
								placeHolder="Selecionar..."
								options={roleItems}
								selectedValue={papel}
								setSelectedValue={setPapel}
								width={"305px"}
							/>
						</div>
					) : null}
					{nivel === 3 ? (
						<div className="admin-container">
							<span className="admin-title">Organização</span>
							<SelectDropdown
								placeHolder="Selecionar..."
								options={state.organizationsNameList}
								selectedValue={organizacao}
								setSelectedValue={setOrganizacao}
								width={"305px"}
							/>
						</div>
					) : null}

					<div className="admin-container">
						<span className="admin-title">Notificações</span>
					</div>
					<div className="admin-container">
						<div className="checkbox-container">
							<input
								className="checkbox-style"
								type="checkbox"
								checked={checkbox1}
								onChange={(e) => setCheckbox1(e.target.checked)}
							/>
							<em className="checkbox-text">
								Notificação por Inatividade
							</em>
						</div>
					</div>

					<div className="admin-container">
						<div className="checkbox-container">
							<input
								className="checkbox-style"
								type="checkbox"
								checked={checkbox2}
								onChange={(e) => setCheckbox2(e.target.checked)}
							/>
							<em className="checkbox-text">
								Alerta de Valores Fora do Normal
							</em>
						</div>
					</div>

					<div className="admin-container">
						<div className="checkbox-container">
							<input
								className="checkbox-style"
								type="checkbox"
								checked={checkbox3}
								onChange={(e) => setCheckbox3(e.target.checked)}
							/>
							<em className="checkbox-text">
								Alarme de Valores Críticos
							</em>
						</div>
					</div>

					{/* <div className="admin-container">
						<span className="admin-title">
							Intervalo de Atualização Automática (minutos)
						</span>
						<input
							className="admin-input"
							type="number"
							name="atualizacaoAutomatica"
							value={atualizacaoAutomatica}
							onChange={(e) =>
								setAtualizacaoAutomatica(e.target.value)
							}
							min={1}
						/>
					</div> */}
					<div>
						<button
							className="change-password-button"
							onClick={() =>
								setNewPasswordField(!newPasswordField)
							}
						>
							<div className="style-password-button">
								<RiLockPasswordLine
									size={"16px"}
									color={"black"}
									className="icon-style"
								/>
								Mudar senha
								<div className="right-container">
									<IoMdArrowDropdown
										size={"16px"}
										color={"black"}
										className="icon-style"
									/>
								</div>
							</div>
						</button>
						{newPasswordField ? (
							<div className="admin-container">
								<span className="admin-title">
									Digitar a nova senha
								</span>
								<em className="checkbox-text">
									(a senha deve conter no mínimo 8 caracteres)
								</em>
								<input
									className="admin-input"
									type="text"
									name="senha"
									value={newPassword}
									maxLength="16"
									minLength="8"
									onChange={(e) =>
										setNewPassword(e.target.value)
									}
								/>
							</div>
						) : null}
					</div>

					<div className="footer">
						<SaveDataButton
							data={{
								id: `${state.userData.id}`,
								organi_user_id:
									nivel === 3
										? `${organizacao.id}`
										: organizacao,
								email: `${state.userData.email}`,
								nome: `${nome}`,
								intervalo_auto: null,
								notifica_inatividade: `${
									checkbox1 ? "on" : null
								}`,
								alerta_valores: `${checkbox3 ? "on" : null}`,
								valores_fora: `${checkbox2 ? "on" : null}`,
								status:
									status.nome === "Habilitado" ? "on" : null,
								nivel: `${papel ? papel.nome : null}`,
								senha:
									newPassword && newPasswordField
										? newPassword
										: senha,
							}}
							action={"put"}
							endpoint={`/user_api/${state.userData.id}`}
							returnTo={nivel === 3 ? "/users" : "/dashboard"}
						/>
						{nivel === 3 ? (
							<DeleteDataButton
								endpoint={`/user_api/${state.userData.id}`}
								returnTo={"/users"}
							/>
						) : null}
					</div>
				</div>
			)}
		</div>
	);
}
