import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

import "./selectDropdown.css";

const Icon = () => {
	return <IoMdArrowDropdown />;
};

const SelectDropdown = ({
	placeHolder,
	options,
	selectedValue,
	setSelectedValue,
	width,
}) => {
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		const handler = () => setShowMenu(false);

		window.addEventListener("click", handler);
		return () => {
			window.removeEventListener("click", handler);
		};
	});

	const handleInputClick = (e) => {
		e.stopPropagation();
		setShowMenu(!showMenu);
	};

	const getDisplay = () => {
		if (selectedValue) {
			return selectedValue.nome;
		}
		return placeHolder;
	};

	const onItemClick = (option) => {
		setSelectedValue(option);
	};

	const isSelected = (option) => {
		if (!selectedValue) return false;

		return selectedValue.id === option.id;
	};

	return (
		<div className="dropdown-container" style={{ width: `${width}` }}>
			<div onClick={handleInputClick} className="dropdown-input">
				<div className="dropdown-selected-value">{getDisplay()}</div>
				<div>
					<Icon />
				</div>
			</div>
			{showMenu && (
				<div className="dropdown-menu" style={{ width: `${width}` }}>
					{options.map((option) => (
						<div
							onClick={() => onItemClick(option)}
							key={option.id}
							className={`dropdown-item ${
								isSelected(option) && "selected"
							}`}
						>
							{option.nome}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default SelectDropdown;
