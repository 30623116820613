export const tempSensor = "Temperatura do Ar";
export const rhSensor = "Umidade Relativa";
export const co2Sensor = "Gás Carbônico";
export const nh3Sensor = "Gás Amônia";
export const waterTempSensor = "Temperatura da Água";
export const flowWaterDiff = "Fluxo da Água Diff";
export const flowWaterAcc = "Fluxo da Água Acc";
export const flowWaterAccPO = "Fluxo da Água Acc Poço";
export const siloLevel = "Silo Level";
export const tankLevel = "TankLevel";
