import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import { LineChart1Element } from "../../charts";
import moment from "moment";

import waterTempIcon from "../../../../assets/icons/water-temperature-icon.png";
import { BiDotsHorizontal } from "react-icons/bi";

import "../panel-styles.css";

export default function WaterTemperatureDataAnimalPanel({ sensor }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [exportData, setExportData] = useState([]);
	const [waterTempArray, setWaterTempArray] = useState([]);
	const [tempLastData, setTempLastData] = useState(null);
	let api_request;

	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 5);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");
		api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request, access_token);
	let getLastData = useGet(
		`evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&ultimo_dado=1`,
		access_token
	);

	useEffect(() => {
		let dataArray = [];
		let exportDataAux = [];
		if (
			!getData.state.loading &&
			!getData.state.error &&
			getData.state.data.length > 0 &&
			!getLastData.state.loading &&
			!getLastData.state.error
		) {
			dataArray = getData.state.data.map((d) => {
				exportDataAux.push({
					id: d.id,
					data: moment
						.unix(d.timestamp / 1000)
						.format("DD/MM/YY HH:mm"),
					temperatura_agua: +d.valor_sensor,
				});

				return {
					timestamp: moment
						.unix(d.timestamp / 1000)
						.format("YYYY-MM-DDTHH:mm:ss"),
					value: +d.valor_sensor,
				};
			});

			exportDataAux = exportDataAux.map((objeto) => {
				const { id: propriedadeRemovida, ...restoDoObjeto } = objeto;
				return restoDoObjeto;
			});
		}

		setExportData(exportDataAux);
		setTempLastData(getLastData.state.data[0]);
		setWaterTempArray(dataArray);
	}, [
		getData.state.loading,
		getData.state.error,
		getData.state.data,
		getLastData.state.loading,
		getLastData.state.error,
		getLastData.state.data,
	]);

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={true} />
	);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Dados de Temperatura da Água</em>
			</div>
			<div>
				{getData.state.loading || getLastData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error || getLastData.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "10px" }}>
						<div className="panel-items">
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Último Valor
							</span>
						</div>
						<div className="last-data-container">
							<div className="last-data-content-container">
								<img
									src={waterTempIcon}
									height={"40px"}
									width={"40px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								{tempLastData !== undefined &&
								!isNaN(+tempLastData.valor_sensor) ? (
									<p className="last-value-text">
										{+tempLastData.valor_sensor} ºC
									</p>
								) : (
									<p className="last-value-text">-- ºC</p>
								)}
							</div>
							{tempLastData !== undefined && tempLastData.data ? (
								<span style={{ fontSize: "10px" }}>
									Dados atualizados em{" "}
									{moment(tempLastData.data).format(
										"DD-MM-YY HH:mm"
									)}
								</span>
							) : (
								<span style={{ fontSize: "10px" }}>
									Dados atualizados em --
								</span>
							)}
						</div>

						<div className="panel-items">
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Gráfico de Temperatura da Água
							</span>
						</div>

						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={true}
						/>
						{waterTempArray.length > 0 ? (
							<div className="chart-container">
								<LineChart1Element
									data={waterTempArray}
									unit={"ºC"}
									color={"#004C99"}
									fileName={"grafico-temp-agua"}
								/>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}

						{waterTempArray.length > 0 ? (
							<div className="panel-items">
								<BiDotsHorizontal />
								<span className="panel-items-title">
									Exportar dados do Gráfico
								</span>
								<div className="right-container">
									<Export
										onExport={() =>
											downloadCSV(
												exportData,
												`dados-temperatura-agua-NS${sensor.serie}`
											)
										}
									/>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}
