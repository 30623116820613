import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";
import { AreaChartDeltaT } from "../../charts";

import "../panel-styles.css";

export default function DeltaTPanel({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [data, setData] = useState([]);

	let getData = useGet(
		`dados_horarios_consulta?gateway=${
			gateway.id_dispositivo
		}&ultimo_dado=${1}`,
		access_token
	);

	useEffect(() => {
		if (
			!getData.state.loading &&
			!getData.state.error &&
			getData.state.data.length > 0
		) {
			setData({
				Data: getData.state.data[0].Data,
				Tmed: getData.state.data[0].Tmed
					? +getData.state.data[0].Tmed
					: NaN,
				URmed: getData.state.data[0].URmed
					? +getData.state.data[0].URmed
					: NaN,
				DeltaT: getData.state.data[0].DeltaT
					? +getData.state.data[0].DeltaT
					: NaN,
			});
		}
	}, [getData.state.loading, getData.state.error, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Delta T</em>
			</div>
			<div>
				{getData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error ? (
					<ErrorMessage />
				) : (
					<div>
						{getData.state.data.length > 0 ? (
							<AreaChartDeltaT
								data={data}
								fileName={"grafico-delta-t"}
							/>
						) : (
							<NoDataAvailableMessage />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
