import errorIcon from "../../../../assets/icons/error-icon.png";
import "../message-panels-styles.css";

export default function ErrorMessage() {
	return (
		<div className="message-container">
			<div className="message-content-container">
				<img src={errorIcon} height={"100px"} width={"100px"} alt="" />
				<span className="message-text-style">
					Ocorreu um erro. Por favor, tente novamente.
				</span>
			</div>
		</div>
	);
}
