import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import SelectDataButton from "../../date-selector";
import PluviometerIcon from "../../../../assets/icons/rain-drops-icon.png";
import { BarChart1Element } from "../../charts";
import { BiDotsHorizontal } from "react-icons/bi";

import moment from "moment";

import "../panel-styles.css";

export default function PluviometerDataPanel({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [pluvData, setPluvData] = useState([]);
	const [sumPluv, setSumPluv] = useState("");

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request, access_token);

	useEffect(() => {
		let data = [];
		let sum = 0;
		if (!getData.state.loading && !getData.state.error) {
			data = getData.state.data.map((d) => {
				if (d.Pacc !== "") {
					let value = +d.Pacc;
					sum += value;

					return {
						timestamp: moment(d.data).format("YYYY-MM-DD"),
						value: value,
					};
				}
				return null;
			});
			data = data.filter((elemento) => elemento !== null);
		}

		setPluvData(data);
		setSumPluv(sum.toFixed(2));
	}, [getData.state.loading, getData.state.error, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Pluviometria por Dia</em>
			</div>
			<div>
				{getData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ marginLeft: "10px" }}>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={false}
						/>
						{pluvData.length > 0 ? (
							<div>
								<div className="panel-items">
									<BiDotsHorizontal />
									<span className="panel-items-title">
										Pluviometria Acumulada
									</span>
								</div>
								<div className="last-data-container">
									<div className="last-data-content-container">
										<img
											src={PluviometerIcon}
											height={"40px"}
											width={"40px"}
											alt=""
											style={{ marginRight: "5px" }}
										/>
										{sumPluv !== undefined &&
										!isNaN(sumPluv) ? (
											<p className="last-value-text">
												{sumPluv} mm
											</p>
										) : (
											<p className="last-value-text">
												-- mm
											</p>
										)}
									</div>
								</div>
								<div className="panel-items">
									<BiDotsHorizontal />
									<span className="panel-items-title">
										Gráfico de Pluviometria
									</span>
								</div>

								<div className="chart-container">
									<BarChart1Element
										data={pluvData}
										unit={"mm"}
										color={"#187BCD"}
										fileName={"grafico-pluviometria-diaria"}
									/>
								</div>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
