import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

export default function SensorCreate() {
	const { state } = useLocation();

	const [tag, setTag] = useState("");
	const [idDispositivo, setIdDispositivo] = useState("");
	const [organizacao, setOrganizacao] = useState("");
	const [gateway, setGateway] = useState("");
	const [datalogger, setDatalogger] = useState("");
	const [numeroSerie, setNumeroSerie] = useState("");
	const [status, setStatus] = useState("");
	const [tipo, setTipo] = useState("");
	const [modelo, setModelo] = useState("");
	const [correcao, setCorrecao] = useState("");
	const [alertaInferior, setAlertaInferior] = useState("");
	const [alertaSuperior, setAlertaSuperior] = useState("");
	const [alarmeInferior, setAlarmeInferior] = useState("");
	const [alarmeSuperior, setAlarmeSuperior] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID *</span>
				<input
					className="admin-input"
					type="text"
					name="guid"
					value={idDispositivo}
					onChange={(e) =>
						setIdDispositivo(e.target.value.toUpperCase())
					}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Tag *</span>
				<input
					className="admin-input"
					type="text"
					name="tag"
					value={tag}
					onChange={(e) => setTag(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Número de Série *</span>
				<input
					className="admin-input"
					type="text"
					name="numeroserie"
					value={numeroSerie}
					onChange={(e) => setNumeroSerie(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Organização</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list.organizationsNameList}
					selectedValue={organizacao}
					setSelectedValue={setOrganizacao}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Concentrador</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list.gatewaysNameList}
					selectedValue={gateway}
					setSelectedValue={setGateway}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Coletor</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list.dataloggersNameList}
					selectedValue={datalogger}
					setSelectedValue={setDatalogger}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Status</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={deviceStatusItems}
					selectedValue={status}
					setSelectedValue={setStatus}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Sobre o Sensor</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Tipo de Sensor</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list.sensorTypeNameList}
					selectedValue={tipo}
					setSelectedValue={setTipo}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Modelo</em>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list.deviceModelsNameList}
					selectedValue={modelo}
					setSelectedValue={setModelo}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Correção</em>
				<input
					className="admin-input"
					type="text"
					name="correcao"
					value={correcao}
					onChange={(e) => setCorrecao(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Definição de Valores</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Alerta Inferior</em>
				<input
					className="admin-input"
					type="text"
					name="alerta-inferior"
					value={alertaInferior}
					onChange={(e) => setAlertaInferior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alerta Superior</em>
				<input
					className="admin-input"
					type="text"
					name="alerta-superior"
					value={alertaSuperior}
					onChange={(e) => setAlertaSuperior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alarme Inferior</em>
				<input
					className="admin-input"
					type="text"
					name="alarme-inferior"
					value={alarmeInferior}
					onChange={(e) => setAlarmeInferior(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Alarme Superior</em>
				<input
					className="admin-input"
					type="text"
					name="alarme-superior"
					value={alarmeSuperior}
					onChange={(e) => setAlarmeSuperior(e.target.value)}
				/>
			</div>

			<div className="footer">
				<SaveDataButton
					data={{
						organi_sensor_id: organizacao ? organizacao.id : null,
						id_dispositivo: idDispositivo,
						tag: tag,
						tipo: tipo ? tipo.id : null,
						serie: +numeroSerie,
						modelo: modelo ? modelo.id : null,
						concentrador: gateway ? gateway.id : null,
						coletor: datalogger ? datalogger.id : null,
						status: status.nome === "Ativo" ? "on" : null,
						alerta_inferior: +alertaInferior,
						alerta_superior: +alertaSuperior,
						alarme_inferior: +alarmeInferior,
						alarme_superior: +alarmeSuperior,
						correcao: +correcao,
					}}
					action={"post"}
					endpoint={`/sensor_api`}
					returnTo={`/sensors`}
				/>
			</div>
		</div>
	);
}
