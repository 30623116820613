import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import { BsFillPeopleFill } from "react-icons/bs";
import { MdRouter, MdDeviceHub, MdSettingsRemote } from "react-icons/md";
import ErrorMessage from "../../dashboard/messages-panel/error-message/error-message";
import NoDeviceConnectedMessage from "../../dashboard/messages-panel/no-device-connected-message";

export default function DeviceTree() {
	const { organizacao, nivel, access_token } = AuthService.getCurrentUser();
	const Navigate = useNavigate();

	const [devicesObj, setDevicesObj] = useState([]);
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewayNameList] = useState(null);
	const [dataloggersNameList, setDataloggersNameList] = useState(null);
	const [deviceModelsNameList, setdeviceModelsNameList] = useState(null);
	const [sensorTypeNameList, setSensorTypeNameList] = useState(null);

	let api_request_org, api_request_gtw, api_request_dl, api_request_sensor;

	switch (nivel) {
		case 2:
			api_request_org = `organizacao_consulta?organi_representante=${organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_representante=${organizacao}`;
			api_request_dl = `coletor_consulta?coletor_representante=${organizacao}`;
			api_request_sensor = `sensor_consulta?sensor_representante=${organizacao}`;
			break;

		case 3:
			api_request_org = "organizacao_api";
			api_request_gtw = "gateway_api";
			api_request_dl = "coletor_api";
			api_request_sensor = "sensor_api";
			break;

		default:
			api_request_org = `organizacao_consulta?organi_id=${organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_organi=${organizacao}`;
			api_request_dl = `coletor_consulta?coletor_organizacao=${organizacao}`;
			api_request_sensor = `sensor_consulta?sensor_organizacao=${organizacao}`;
			break;
	}

	let getOrganizations = useGet(api_request_org, access_token);
	let getGateways = useGet(api_request_gtw, access_token);
	let getDataloggers = useGet(api_request_dl, access_token);
	let getSensors = useGet(api_request_sensor, access_token);
	let getDeviceModels = useGet("modelo_api", access_token);
	let sensorTypeList = useGet("tipo_sensor_api", access_token);

	useEffect(() => {
		if (
			!getOrganizations.state.loading &&
			!getOrganizations.state.error &&
			!getGateways.state.loading &&
			!getGateways.state.error &&
			!getDataloggers.state.loading &&
			!getDataloggers.state.error &&
			!getSensors.state.loading &&
			!getSensors.state.error &&
			!getDeviceModels.state.loading &&
			!getDeviceModels.state.error &&
			!sensorTypeList.state.loading &&
			!sensorTypeList.state.error
		) {
			let orgs, gtws, dls, sensors;

			orgs = getOrganizations.state.data;
			gtws = getGateways.state.data;
			dls = getDataloggers.state.data;
			sensors = getSensors.state.data;

			if (nivel === 3) {
				setOrganizationsNameList(orgs);

				let aux = gtws.map((objeto) => {
					return { id: objeto.id, nome: objeto.tag };
				});

				setGatewayNameList(aux);

				let aux2 = dls.map((objeto) => {
					return { id: objeto.id, nome: objeto.tag };
				});

				setDataloggersNameList(aux2);
			}

			// Structure to be used:
			// {organization: {}, devices: [{gateway: {}, devices_connected: [{datalogger: {}, sensors: [{}]}]}]}
			let tree = orgs.map((org) => {
				let gtwList = gtws.filter(
					(gtw) => gtw.organi_concentra_id === org.id
				);

				let devices = gtwList.map((gtw) => {
					let dlList = dls.filter((dl) => dl.concentrador === gtw.id);

					let dataloggerAndsensorList = dlList.map((dl) => {
						return {
							datalogger: dl,
							sensors: sensors.filter((s) => s.coletor === dl.id),
						};
					});

					return {
						gateway: gtw,
						devices_connected: dataloggerAndsensorList,
					};
				});

				return {
					organization: org,
					devices: devices,
				};
			});

			setDevicesObj(tree);
			setdeviceModelsNameList(getDeviceModels.state.data);
			setSensorTypeNameList(sensorTypeList.state.data);
		}
	}, [
		nivel,
		getOrganizations.state.loading,
		getOrganizations.state.error,
		getOrganizations.state.data,
		getGateways.state.loading,
		getGateways.state.error,
		getGateways.state.data,
		getDataloggers.state.loading,
		getDataloggers.state.error,
		getDataloggers.state.data,
		getSensors.state.loading,
		getSensors.state.error,
		getSensors.state.data,
		getDeviceModels.state.loading,
		getDeviceModels.state.error,
		getDeviceModels.state.data,
		sensorTypeList.state.loading,
		sensorTypeList.state.error,
		sensorTypeList.state.data,
	]);

	return (
		<div className="page">
			{getDataloggers.state.loading &&
			getOrganizations.state.loading &&
			getGateways.state.loading &&
			getSensors.state.loading &&
			getDeviceModels.state.loading &&
			sensorTypeList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getDataloggers.state.error ||
			  getOrganizations.state.error ||
			  getGateways.state.error ||
			  getSensors.state.error ||
			  getDeviceModels.state.error ||
			  sensorTypeList.state.error ? (
				<ErrorMessage />
			) : devicesObj.length === 0 ? (
				<NoDeviceConnectedMessage />
			) : (
				<div className="device-tree-container">
					{devicesObj.map((item) => (
						<ul className="tree" key={item.organization.id}>
							<li>
								<details
									open // recolhe os itens
								>
									<summary>
										<button
											className="redirect-button-custom"
											onClick={() =>
												nivel === 3
													? Navigate(
															`/organizations/${item.organization.id}`,
															{
																state: {
																	organizationData:
																		item.organization,
																	organizationsNameList,
																},
															}
													  )
													: null
											}
										>
											<BsFillPeopleFill
												className="device-tree-icon-style"
												size={20}
											/>
											{item.organization.nome}
										</button>
									</summary>
									{item.devices.map((d) => (
										<ul key={d.gateway.id}>
											<li>
												<details
													open // recolhe os itens
												>
													<summary>
														<button
															className="redirect-button-custom"
															onClick={() =>
																nivel === 3
																	? Navigate(
																			`/gateways/${d.gateway.id}`,
																			{
																				state: {
																					gatewayData:
																						d.gateway,
																					organizationsNameList,
																				},
																			}
																	  )
																	: null
															}
														>
															<MdRouter
																className="device-tree-icon-style"
																size={20}
															/>
															{d.gateway.tag}
														</button>
													</summary>
													{d.devices_connected.map(
														(dl) => (
															<ul
																key={
																	dl
																		.datalogger
																		.id
																}
															>
																<li>
																	<details
																		open // recolhe os itens
																	>
																		<summary>
																			<button
																				className="redirect-button-custom"
																				onClick={() =>
																					nivel ===
																					3
																						? Navigate(
																								`/dataloggers/${dl.datalogger.id}`,
																								{
																									state: {
																										dataloggerData:
																											dl.datalogger,
																										organizationsNameList,
																										gatewaysNameList,
																									},
																								}
																						  )
																						: null
																				}
																			>
																				<MdDeviceHub
																					className="device-tree-icon-style"
																					size={
																						20
																					}
																				/>
																				{
																					dl
																						.datalogger
																						.tag
																				}
																			</button>
																		</summary>
																		{dl.sensors.map(
																			(
																				s
																			) => (
																				<ul
																					key={
																						s.id
																					}
																				>
																					<li>
																						<button
																							className="redirect-button-custom"
																							onClick={() =>
																								nivel ===
																								3
																									? Navigate(
																											`/sensors/${s.id}`,
																											{
																												state: {
																													sensorData:
																														s,
																													organizationsNameList,
																													gatewaysNameList,
																													dataloggersNameList,
																													deviceModelsNameList,
																													sensorTypeNameList,
																												},
																											}
																									  )
																									: null
																							}
																						>
																							<MdSettingsRemote
																								className="device-tree-icon-style"
																								size={
																									20
																								}
																							/>
																							{
																								s.tag
																							}
																						</button>
																					</li>
																				</ul>
																			)
																		)}
																	</details>
																</li>
															</ul>
														)
													)}
												</details>
											</li>
										</ul>
									))}
								</details>
							</li>
						</ul>
					))}
				</div>
			)}
		</div>
	);
}
