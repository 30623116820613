import { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth';

import { useGet } from '../../../reducers/useGet';

import DataTable from 'react-data-table-component';
import AddButton from '../../../components/data-table-custom/add-button';
import SearchBar from '../../../components/data-table-custom/search-bar';
import ExportButton from '../../../components/data-table-custom/export-button';
import downloadCSV from '../../../scripts/export-script';
import { paginationComponentOptions } from '../../../components/data-table-custom/dataTableCustom';
import ErrorMessage from '../../dashboard/messages-panel/error-message';

export default function UserList() {
	let { access_token } = AuthService.getCurrentUser();
	let usersList = useGet('user_api', access_token);
	let organizationsList = useGet('organizacao_api', access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState([]);
	const [organizationsNameList, setOrganizationsNameList] = useState([]);

	useEffect(() => {
		if (
			!usersList.state.loading &&
			!usersList.state.error &&
			!organizationsList.state.loading &&
			!organizationsList.state.error
		) {
			const enrichedData = usersList.state.data.map((user) => {
				const organization = organizationsList.state.data.find(
					(org) => org.id === user.organi_user_id
				);
				return {
					...user,
					organization: organization ? organization.nome : '',
				};
			});

			setData(enrichedData);
			setRecords(enrichedData);

			setOrganizationsNameList(organizationsList.state.data);
		}
	}, [
		usersList.state.loading,
		usersList.state.error,
		usersList.state.data,
		organizationsList.state.loading,
		organizationsList.state.error,
		organizationsList.state.data,
		organizationsNameList,
	]);

	const columns = [
		{ name: 'ID', selector: (row) => row.id, sortable: true },
		{ name: 'Nome', selector: (row) => row.nome, sortable: true },
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Organização',
			selector: (row) => row.organization,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => (row.status === 'on' ? 'Habilitado' : 'Desabilitado'),
			sortable: true,
		},
		{
			name: 'Papel',
			selector: (row) => row.nivel,
			sortable: true,
		},
	];

	const openUserDetail = (userData) => {
		Navigate(`/users/${userData.id}`, {
			state: { userData, organizationsNameList },
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={() => onExport(data)} chartPanel={false} />
	);

	return (
		<div className="page">
			{usersList.state.loading || organizationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : usersList.state.error || organizationsList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar table={'users'} setRecords={setRecords} data={data} />
						<Export onExport={downloadCSV} />
						<AddButton
							goTo={'/users/add-new-user'}
							list={organizationsNameList}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Usuário encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={openUserDetail}
					/>
				</div>
			)}
		</div>
	);
}
