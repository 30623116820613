import React from "react";
import Plot from "react-plotly.js";

import moment from "moment";

export default function LineChart2Elements({
	data,
	unit,
	name1,
	name2,
	color1,
	color2,
	fileName,
}) {
	const values = [
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.value1),
			name: `${name1}`,
			mode: "lines",
			line: { color: `${color1}` },
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY HH:mm"
					)}<br><br>${name1}: ${item.value1}${unit}<br>${name2}:
						${item.value2}${unit}`
			),
			textfont: {
				size: 10,
				color: color1,
			},
			hoverinfo: "text",
		},
		{
			x: data.map((item) => item.timestamp),
			y: data.map((item) => item.value2),
			name: `${name2}`,
			mode: "lines",
			line: { color: `${color2}` },
			text: data.map(
				(item) =>
					`${moment(item.timestamp).format(
						"DD/MM/YY HH:mm"
					)}<br><br>${name1}: ${item.value1}${unit}<br>${name2}:
						${item.value2}${unit}`
			),
			textfont: {
				size: 10,
				color: color2,
			},
			hoverinfo: "text",
		},
	];

	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				xaxis: {
					tickformat: "%d/%m/%y <br> %H:%M",
					tickfont: {
						size: 9,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 40, // Margem esquerda
					r: 10, // Margem direita
					t: 40, // Margem superior
					b: 40, // Margem inferior
				},
				hoverlabel: {
					bgcolor: "rgba(51,51,51,255)", // Cor de fundo do tooltip
					font: { color: "white" }, // Cor do texto do tooltip
					align: "left",
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
				showlegend: true,
				legend: {
					orientation: "h",
					xanchor: "center",
					x: 0.5,
					y: 1.2,
				},
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "300px",
			}}
		/>
	);
}
