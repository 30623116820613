export function diffFunction(eventsList, ajuste) {
	let current = 0;
	let next = 0;
	let result = [];

	if (eventsList.length >= 2) {
		let counter = 0;

		for (let i = 0; i < eventsList.length - 1; i++) {
			current = eventsList[i].value;
			next = eventsList[i + 1].value;

			let diff = next - current;

			if (Math.sign(diff) === -1) {
				counter += 1;
			}

			let value_to_return =
				counter * (Math.pow(2, 16) / 24000) * ajuste + next;

			result.push({
				timestamp: eventsList[i + 1].timestamp,
				value: value_to_return,
			});
		}
	}

	return result;
}
