import { useEffect, useReducer, useRef } from "react";
import axios from "axios";

import { BASE_URL } from "../services/api";

const initialState = {
	loading: true,
	error: "",
	data: {},
};

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_SUCCESS":
			return {
				loading: false,
				data: action.payload,
				error: "",
			};
		case "FETCH_ERROR":
			return {
				loading: false,
				data: {},
				error: "Something went Wrong",
			};
		default:
			return state;
	}
};

export function useGet(endpoint, access_token) {
	const [state, dispatch] = useReducer(reducer, initialState);

	const isMountedRef = useIsMountedRef();

	useEffect(() => {
		const fetchData = async () => {
			await axios
				.get(`${BASE_URL}/${endpoint}`, {
					headers: {
						Authorization: `Bearer ${access_token}`,
					},
				})
				.then(({ data }) => {
					if (isMountedRef.current) {
						dispatch({
							type: "FETCH_SUCCESS",
							payload: data,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: "FETCH_ERROR" });
				});
		};
		if (endpoint) fetchData();
	}, [access_token, isMountedRef, endpoint]);

	return { state };
}

// https://www.debuggr.io/react-update-unmounted-component/
function useIsMountedRef() {
	const isMountedRef = useRef(null);
	useEffect(() => {
		isMountedRef.current = true;
		return () => (isMountedRef.current = false);
	});
	return isMountedRef;
}
