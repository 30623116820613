import { useState } from "react";
import axios from "axios";
import requestConfig from "../../reducers/request_config";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";

export default function SendEmailForgetPassword({ text, user_email }) {
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);

	const [showAlert, setShowAlert] = useState(false);

	const handleSaveData = async (e) => {
		e.preventDefault();

		try {
			const resp = await axios.request(
				requestConfig({
					method: "get",
					endpoint: "/send_email",
					access_token: "",
					params: {
						tipo_email: "redefinir",
						user_email: `${user_email}`,
					},
					data: [],
				})
			);
			setResponse(resp);
			setShowAlert(true);
		} catch (e) {
			setErrorStatus(true);
			setError("E-mail inválido, digite novamente.");
			setShowAlert(true);
		}

		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	};

	const alertMessage = () => {
		setShowAlert(true);

		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	};

	return (
		<div>
			<button
				className="send-data-button-custom"
				onClick={user_email ? handleSaveData : alertMessage}
			>
				{text}
			</button>
			{showAlert ? (
				<AlertComponent
					message={"Digite o seu e-mail no campo acima."}
					code={"ERROR"}
				/>
			) : null}
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{response.status === 200 && showAlert ? (
				<AlertComponent message={"E-mail enviado!"} code={"SUCCESS"} />
			) : null}
		</div>
	);
}
