import React, { useState } from "react";

import DashboardButtons from "../dashboard-buttons";

import HourlyData from "./hourly-data";
import DailyData from "./daily-data";
import HydrologicalBalanceData from "./hydrological-balance-data";
import PulverizacaoData from "./pulverization-data";
import SoilData from "./soil-data";

export default function IrrigationDashboard({ gateway }) {
	// Tipos de Botão:
	// 1 - horario
	// 2 - diario
	// 3 - Balanço Hídrico
	// 4 - Pulverização
	// 5 - Dados dos Tensiômetros
	const [button1, setButton1] = useState(false);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);
	const [button4, setButton4] = useState(false);
	const [button5, setButton5] = useState(false);

	return (
		<div>
			<div className="dropdown-button-container">
				<DashboardButtons
					buttonType={"horario"}
					buttonNumber={button1}
					setButtonNumber={setButton1}
					buttonTitle={"Dados Climáticos Horários"}
					reference={gateway}
				/>
				{button1 ? <HourlyData gateway={gateway} /> : null}
			</div>

			<div className="dropdown-button-container">
				<DashboardButtons
					buttonType={"diario"}
					buttonNumber={button2}
					setButtonNumber={setButton2}
					buttonTitle={"Dados Climáticos Diários"}
					reference={gateway}
				/>
				{button2 ? <DailyData gateway={gateway} /> : null}
			</div>

			<div className="dropdown-button-container">
				<DashboardButtons
					buttonType={"balanco-hidrico"}
					buttonNumber={button3}
					setButtonNumber={setButton3}
					buttonTitle={"Balanço Hídrico"}
					reference={gateway}
				/>
				{button3 ? <HydrologicalBalanceData gateway={gateway} /> : null}
			</div>

			<div className="dropdown-button-container">
				<DashboardButtons
					buttonType={"pulverizacao"}
					buttonNumber={button4}
					setButtonNumber={setButton4}
					buttonTitle={"Pulverização"}
					reference={gateway}
				/>
				{button4 ? <PulverizacaoData gateway={gateway} /> : null}
			</div>
			<div className="dropdown-button-container">
				<DashboardButtons
					buttonType={"sensores-solo"}
					buttonNumber={button5}
					setButtonNumber={setButton5}
					buttonTitle={"Dados dos Sensores de Solo"}
					reference={gateway}
				/>
				{button5 ? <SoilData gateway={gateway} /> : null}
			</div>
		</div>
	);
}
