import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import DashboardHeader from "../../dashboard/dashboard-header";
import DeviceStatusChartPanel from "./device-status-chart-panel";
import NoDeviceConnected from "../../dashboard/messages-panel/no-device-connected-message";
import ErrorMessage from "../../dashboard/messages-panel/error-message";
import { FaCircle } from "react-icons/fa";
import { BiDotsHorizontal } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { RiLineChartLine } from "react-icons/ri";

import moment from "moment";

export default function DeviceStatus() {
  const { access_token, organizacao, nivel } = AuthService.getCurrentUser();

  const [organizationList, setOrganizationList] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [devicesObj, setDevicesObj] = useState([]);

  let api_get_organizations,
    api_get_gateways,
    api_get_dataloggers,
    api_get_sensors;

  switch (nivel) {
    case 2:
      api_get_organizations = `organizacao_consulta?organi_representante=${organizacao}`;
      api_get_gateways = `gateway_consulta?gateway_representante=${organizacao}`;
      api_get_dataloggers = `coletor_consulta?coletor_representante=${organizacao}`;
      api_get_sensors = `sensor_consulta?sensor_representante=${organizacao}`;
      break;

    case 3:
      api_get_organizations = "organizacao_api";
      api_get_gateways = "gateway_api";
      api_get_dataloggers = "coletor_api";
      api_get_sensors = "sensor_api";
      break;

    default:
      api_get_organizations = `organizacao_consulta?organi_id=${organizacao}`;
      api_get_gateways = `gateway_consulta?gateway_organi=${organizacao}`;
      api_get_dataloggers = `coletor_consulta?coletor_organizacao=${organizacao}`;
      api_get_sensors = `sensor_consulta?sensor_organizacao=${organizacao}`;
      break;
  }

  const getOrganizations = useGet(api_get_organizations, access_token);
  const getGateways = useGet(api_get_gateways, access_token);
  const getDataloggers = useGet(api_get_dataloggers, access_token);
  const getSensors = useGet(api_get_sensors, access_token);

  useEffect(() => {
    if (
      !getOrganizations.state.loading &&
      !getOrganizations.state.error &&
      !getGateways.state.loading &&
      !getGateways.state.error &&
      !getDataloggers.state.loading &&
      !getDataloggers.state.error &&
      !getSensors.state.loading &&
      !getSensors.state.error
    ) {
      setOrganizationList(getOrganizations.state.data);
    }
  }, [
    nivel,
    getOrganizations.state.loading,
    getOrganizations.state.error,
    getOrganizations.state.data,
    getGateways.state.loading,
    getGateways.state.error,
    getDataloggers.state.loading,
    getDataloggers.state.error,
    getSensors.state.loading,
    getSensors.state.error,
  ]);

  useEffect(() => {
    if (organization) {
      let gatewaysList = getGateways.state.data;
      let dataloggersList = getDataloggers.state.data;
      let sensorsList = getSensors.state.data;

      gatewaysList = gatewaysList.filter(
        (gtw) => organization.id === gtw.organi_concentra_id
      );
      dataloggersList = dataloggersList.filter(
        (dl) => organization.id === dl.organi_coletor_id
      );
      sensorsList = sensorsList.filter(
        (s) => organization.id === s.organi_sensor_id
      );

      let devices = gatewaysList.map((d) => {
        let dls = dataloggersList.filter((dl) => d.id === dl.concentrador);

        let aux = dls.map((col) => {
          let sensors = sensorsList.filter((s) => col.id === s.coletor);
          return { datalogger: col, sensors: sensors };
        });

        return {
          gateway_data: d,
          devices_connected: aux,
        };
      });

      setDevicesObj(devices);
    }
  }, [
    nivel,
    organization,
    getGateways.state.data,
    getDataloggers.state.data,
    getSensors.state.data,
  ]);

  return (
    <div className="page">
      {getOrganizations.state.loading &&
      getGateways.state.loading &&
      getDataloggers.state.loading &&
      getSensors.state.loading ? (
        <div className="loading-container">
          <ReactLoading
            type="bubbles"
            color="var(--main-color)"
            width="50px"
            height="50px"
          />
        </div>
      ) : getOrganizations.state.error ||
        getGateways.state.error ||
        getDataloggers.state.error ||
        getSensors.state.error ? (
        <ErrorMessage />
      ) : (
        <div>
          <DashboardHeader
            item={organization}
            setItem={setOrganization}
            itemsList={organizationList}
            headerText={"Equipamentos da Organização"}
          />
          {organization ? (
            <div>
              {devicesObj.length > 0 ? (
                <div className="grid-container">
                  {devicesObj.map((item) => (
                    <div className="panel-container" key={item.gateway_data.id}>
                      <div className="panel-container-title">
                        <em className="panel-title">{item.gateway_data.tag}</em>
                      </div>
                      <TextItem text="Status do Gateway" />

                      <LastData
                        device={"gateway"}
                        deviceData={item.gateway_data}
                        access_token={access_token}
                      />

                      <TextItem
                        text="Status dos Coletores e
													Sensores"
                      />

                      {item.devices_connected.length > 0 ? (
                        <div>
                          {item.devices_connected.map((devices) => (
                            <ul className="tree" key={devices.datalogger.id}>
                              <li>
                                <details
                                  open // recolhe os itens
                                >
                                  <summary>{devices.datalogger.tag}</summary>
                                  <ul>
                                    <LastData
                                      device={"datalogger"}
                                      deviceData={devices.datalogger}
                                      access_token={access_token}
                                    />
                                    <li>
                                      <details
                                      // open // recolhe os itens
                                      >
                                        <summary>Sensores</summary>
                                        {devices.sensors.map((sensor) => (
                                          <ul key={sensor.id}>
                                            <li>
                                              <details open>
                                                <summary>{sensor.tag}</summary>

                                                <ul>
                                                  <LastData
                                                    device={"sensor"}
                                                    deviceData={sensor}
                                                    access_token={access_token}
                                                  />
                                                </ul>
                                              </details>
                                            </li>
                                          </ul>
                                        ))}
                                      </details>
                                    </li>
                                  </ul>
                                </details>
                              </li>
                            </ul>
                          ))}
                        </div>
                      ) : (
                        <NoDeviceConnected />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <NoDeviceConnected />
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

function TextItem({ text }) {
  return (
    <div className="status-panel-items-title">
      <BiDotsHorizontal />
      <span className="panel-items-title">{text}</span>
    </div>
  );
}

function LastData({ device, deviceData, access_token }) {
  const { nivel } = AuthService.getCurrentUser();
  const [lastData, setLastData] = useState(null);
  const [statusColor, setStatusColor] = useState("gray");

  let api_request;
  if (device === "gateway") {
    api_request = `evento_consulta?tipo_mensagem=2&gateway=${deviceData.id_dispositivo}&ultimo_dado=1`;
  } else if (device === "datalogger") {
    api_request = `evento_consulta?tipo_mensagem=1&coletor=${deviceData.id_dispositivo}&ultimo_dado=1`;
  } else {
    api_request = `evento_consulta?tipo_mensagem=1&sensor=${deviceData.id_dispositivo}&ultimo_dado=1`;
  }

  let getLastData = useGet(api_request, access_token);

  useEffect(() => {
    if (!getLastData.state.loading && getLastData.state.data.length > 0) {
      let now = new Date();

      let lastTimestampToLastData = moment.unix(
        getLastData.state.data[0].timestamp / 1000
      );

      let lastDataTime = new Date(lastTimestampToLastData);

      let millisecondsDiff = now - lastDataTime;

      let secondsDiff = millisecondsDiff / 1000;
      let minutesDiff = secondsDiff / 60;

      // Green = online
      // Gray = +2h offline = +120min offline
      if (minutesDiff < 120) setStatusColor("green");

      setLastData(getLastData.state.data[0]);
    }
  }, [getLastData.state.loading, getLastData.state.data]);

  let LoadingPanel = () => {
    const loadingElement = React.createElement(
      ReactLoading,
      {
        type: "bubbles",
        color: "var(--main-color)",
        width: "30px",
        height: "30px",
      },
      null
    );

    const contentElement =
      device === "gateway"
        ? React.createElement(
            "div",
            { className: "status-panel-items" },
            loadingElement
          )
        : React.createElement("li", null, loadingElement);

    return contentElement;
  };

  let TimePanel = () => {
    return (
      <div
        className={
          device === "gateway" ? "status-panel-items" : "status-panel-items-1"
        }
      >
        <span className="span-li">Data:</span>
        <FaCircle color={statusColor} />
        <span style={{ marginLeft: "5px" }}>
          {moment.unix(lastData.timestamp / 1000).format("DD/MM/YY HH:mm")}
        </span>
      </div>
    );
  };

  let TemperaturePanel = () => {
    return (
      <div
        className={
          device === "gateway" ? "status-panel-items" : "status-panel-items-1"
        }
      >
        <span className="span-li">Temperatura:</span>
        <span style={{ marginLeft: "5px" }}>{lastData.cpu_temp} °C</span>
      </div>
    );
  };

  let FrequencyPanel = () => {
    return (
      <div
        className={
          device === "gateway" ? "status-panel-items" : "status-panel-items-1"
        }
      >
        <span className="span-li">Frequência:</span>
        <span style={{ marginLeft: "5px" }}>{lastData.cpu_freq} MHz</span>
      </div>
    );
  };

  let ItemWithChartButton = (props) => {
    const [openPanel, setOpenPanel] = useState(false);

    return (
      <li
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <button
          className="open-panel-button"
          onClick={() => setOpenPanel(true)}
          title="Abrir Gráfico"
        >
          <RiLineChartLine size={"20px"} style={{ marginLeft: "-8px" }} />
        </button>
        <span className="span-li">{props.buttonName}</span>
        <span>{props.itemData}</span>

        {openPanel ? (
          <DeviceStatusChartPanel
            setOpenPanel={setOpenPanel}
            deviceData={props.deviceData}
            dataType={props.dataType}
            device={props.device}
          />
        ) : null}
      </li>
    );
  };

  const NoDataMessage = ({ device }) => {
    const elemento = device !== "gateway" ? "li" : "div";

    const propsComuns = {
      className:
        device === "gateway" ? "status-panel-items" : "status-panel-items-1",
      children: [
        <FiAlertTriangle key="icon" color={"black"} />,
        <span key="text" style={{ fontSize: "12px", marginLeft: "10px" }}>
          Não há dados no momento...
        </span>,
      ],
    };

    return elemento && React.createElement(elemento, propsComuns);
  };

  return (
    <div>
      {getLastData.state.loading ? (
        <LoadingPanel />
      ) : lastData ? (
        <div>
          {device === "gateway" ? (
            <div>
              <TimePanel />
              <TemperaturePanel />
              <FrequencyPanel />
            </div>
          ) : device === "datalogger" ? (
            <div>
              <div>
                {/* <li> */}
                <ItemWithChartButton
                  buttonName={"Bateria [V]:"}
                  itemData={lastData.batStt}
                  deviceData={deviceData}
                  dataType={"Bateria"}
                  device={device}
                />
                {/* <li> */}
                <ItemWithChartButton
                  buttonName={"RSSI (up/dw) [dBm]:"}
                  itemData={`${lastData.rssiUp}/${lastData.rssiDw}`}
                  deviceData={deviceData}
                  dataType={"RSSI"}
                  device={device}
                />
                {/* <li> */}
                <ItemWithChartButton
                  buttonName={"SNR (up/dw) [dB]:"}
                  itemData={`${lastData.snrUp}/${lastData.snrDw}`}
                  deviceData={deviceData}
                  dataType={"SNR"}
                  device={device}
                />
              </div>

              <li>
                <TimePanel />
              </li>
            </div>
          ) : device === "sensor" ? (
            <div>
              <li>
                <span className="span-li">Valor:</span>
                <span>{lastData.valor_sensor}</span>
              </li>
              <li>
                <TimePanel />
              </li>
              <ItemWithChartButton
                buttonName={"Gráfico"}
                itemData={null}
                deviceData={deviceData}
                dataType={deviceData.tag}
                device={device}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <NoDataMessage device={device} />
      )}
    </div>
  );
}
