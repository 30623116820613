import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import requestConfig from "../../reducers/request_config";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";
import { RiDeleteBin2Line } from "react-icons/ri";

export default function DeleteDataButton({ endpoint, returnTo }) {
	const Navigate = useNavigate();
	const user = AuthService.getCurrentUser();
	const [isLoading, setIsloading] = useState(false);
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const handleDeleteData = async (e) => {
		e.preventDefault();

		setIsloading(true);

		try {
			const resp = await axios.request(
				requestConfig({
					method: "delete",
					endpoint: endpoint,
					access_token: user.access_token,
					data: [],
				})
			);
			setResponse(resp);
		} catch (e) {
			setErrorStatus(true);
			setError(e.message);
			setShowAlert(true);
		} finally {
			setIsloading(false);
		}

		setTimeout(() => {
			setShowAlert(false);
			Navigate(returnTo);
		}, 2000);
	};

	return (
		<div>
			<button
				className="data-table-delete-button-custom"
				onClick={handleDeleteData}
			>
				<RiDeleteBin2Line size={"16px"} className="icon-style" />
				Deletar
			</button>
			{isLoading ? (
				<AlertComponent message={"Carregando..."} code={"LOADING"} />
			) : null}
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{response.status === 200 ? (
				<AlertComponent
					message={"Item removido com sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
		</div>
	);
}
