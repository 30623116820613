import axios from "axios";
import { BASE_URL } from "./api";

const login = (email, password) => {
	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: `${BASE_URL}/login`,
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify({
			email: email,
			senha: password,
		}),
	};

	return axios.request(config).then((response) => {
		if (response.data.access_token) {
			let config = {
				method: "get",
				maxBodyLength: Infinity,
				url: `${BASE_URL}/user_consulta?user_email=${email}`,
				headers: {
					Authorization: `Bearer ${response.data.access_token}`,
				},
			};

			return axios.request(config).then((userData) => {
				let user_level;

				if (userData.data[0].nivel.includes("Admin")) user_level = 3;
				else if (userData.data[0].nivel.includes("Representante"))
					user_level = 2;
				else if (userData.data[0].nivel.includes("Cliente"))
					user_level = 1;
				else user_level = 0;

				let user = {
					access_token: response.data.access_token,
					email: email,
					nivel: user_level,
					organizacao: userData.data[0].organi_user_id,
					id: userData.data[0].id,
				};

				localStorage.setItem("user", JSON.stringify(user));
			});
		}

		return response.data[0];
	});
};

const logout = () => {
	localStorage.removeItem("user");
};

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
	login,
	logout,
	getCurrentUser,
};

export default AuthService;
