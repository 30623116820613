import "./dataTableCustom.css";
import { BiSearchAlt2 } from "react-icons/bi";

export default function SearchBar({ table, setRecords, data }) {
	function filterGateways(query, row) {
		return (
			row.id.toString().includes(query) ||
			row.id_dispositivo.toLowerCase().includes(query) ||
			(row.tag && row.tag.toLowerCase().includes(query)) ||
			row.serie.toString().includes(query) ||
			(row.organization && row.organization.toLowerCase().includes(query)) ||
			(row.status === "on" ? "Ativo" : "Inativo").toLowerCase().includes(query)
		);
	}

	function filterDataloggers(query, row) {
		return (
			row.id.toString().includes(query) ||
			row.id_dispositivo.toLowerCase().includes(query) ||
			(row.tag && row.tag.toLowerCase().includes(query)) ||
			row.serie.toString().includes(query) ||
			(row.organization && row.organization.toLowerCase().includes(query)) ||
			(row.gateway && row.gateway.toLowerCase().includes(query)) ||
			(row.status === "on" ? "Ativo" : "Inativo").toLowerCase().includes(query)
		);
	}

	function filterSensors(query, row) {
		return (
			row.id.toString().includes(query) ||
			row.id_dispositivo.toLowerCase().includes(query) ||
			(row.organization && row.organization.toLowerCase().includes(query)) ||
			row.serie.toString().includes(query) ||
			(row.status === "on" ? "Ativo" : "Inativo").toLowerCase().includes(query)
		);
	}

	function filterManegementZones(query, row) {
		return (
			row.id.toString().includes(query) || (row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterDeviceModels(query, row) {
		return (
			row.id.toString().includes(query) ||
			(row.nome && row.nome.toLowerCase().includes(query)) ||
			(row.unidade && row.unidade.toLowerCase().includes(query)) ||
			(row.conversao && row.conversao.toLowerCase().includes(query))
		);
	}

	function filterSensorTypes(query, row) {
		return (
			row.id.toString().includes(query) || (row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterCultivationTypes(query, row) {
		return (
			row.id.toString().includes(query) || (row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterIrrigationTypes(query, row) {
		return (
			row.id.toString().includes(query) || (row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterSoilTypes(query, row) {
		return (
			row.id.toString().includes(query) || (row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterOrganizations(query, row) {
		return (
			row.id.toString().includes(query) ||
			(row.nivel && row.nivel.toLowerCase().includes(query)) ||
			(row.nome && row.nome.toLowerCase().includes(query))
		);
	}

	function filterUsers(query, row) {
		return (
			(row.email && row.email.toLowerCase().includes(query)) ||
			row.id.toString().includes(query) ||
			(row.nivel && row.nivel.toLowerCase().includes(query)) ||
			(row.nome && row.nome.toLowerCase().includes(query)) ||
			(row.organization && row.organization.toLowerCase().includes(query)) ||
			(row.status === "on" ? "Habilitado" : "Desabilitado").toLowerCase().includes(query)
		);
	}

	function handleFilter(event) {
		const query = event.target.value.toLowerCase();
		let newData = [];

		switch (table) {
			case "gateways":
				newData = data.filter((row) => filterGateways(query, row));
				break;
			case "dataloggers":
				newData = data.filter((row) => filterDataloggers(query, row));
				break;
			case "sensors":
				newData = data.filter((row) => filterSensors(query, row));
				break;
			case "manegementZones":
				newData = data.filter((row) => filterManegementZones(query, row));
				break;
			case "deviceModels":
				newData = data.filter((row) => filterDeviceModels(query, row));
				break;
			case "sensorTypes":
				newData = data.filter((row) => filterSensorTypes(query, row));
				break;
			case "irrigationTypes":
				newData = data.filter((row) => filterIrrigationTypes(query, row));
				break;
			case "cultivationTypes":
				newData = data.filter((row) => filterCultivationTypes(query, row));
				break;
			case "soilTypes":
				newData = data.filter((row) => filterSoilTypes(query, row));
				break;
			case "organizations":
				newData = data.filter((row) => filterOrganizations(query, row));
				break;
			case "users":
				newData = data.filter((row) => filterUsers(query, row));
				break;
			default:
				break;
		}

		setRecords(newData);
	}

	return (
		<div className="search-bar-container">
			<BiSearchAlt2 size={"20px"} className="search-icon" />
			<input
				type="text"
				placeholder="Pesquisar"
				onChange={handleFilter}
				className="search-bar-custom"
			/>
		</div>
	);
}
