import soilInfographicImage from "../../../../../assets/soil-infographic.png";
import "../../panel-styles.css";

export default function AvailableWaterPanel(props) {
	return (
		<div className="alerts-container" style={{ width: "250px" }}>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">
					Água Facilmente Disponível
				</span>
			</div>
			<div className="alerts-panel-content">
				<div className="ad-panel-content-container">
					<div
						className="ad-panel-values-container"
						style={{
							marginRight: "auto",
							backgroundColor: `${props.colorAW60Panel}`,
						}}
					>
						<span className="ad-panel-values-text">
							{props.currentAvailableWater60} mm
						</span>
					</div>
					<div className="ad-panel-20-value-container ">
						<div
							className="ad-panel-values-container"
							style={{
								marginLeft: "auto",
								backgroundColor: `${props.colorAW20Panel}`,
							}}
						>
							<h4 className="ad-panel-values-text">
								{props.currentAvailableWater20} mm
							</h4>
						</div>
					</div>
				</div>
				<div className="ad-panel-content-container">
					<div className="ad-panel-content-container-align">
						<div className="ad-panel-first-column-size">
							<div className="ad-panel-first-column-color">
								<div className="ad-panel-second-column-color">
									<div
										style={{
											backgroundColor: `${props.colorAW60Panel}`,
											borderRadius: 25,
											height: `${props.percentageAvailableWater60}%`,
											width: "100%",
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
					<img src={soilInfographicImage} height={"170px"} alt="" />
					<div className="ad-panel-content-container-align">
						<div className="ad-panel-first-column-size">
							<div
								className="ad-panel-first-column-color"
								style={{
									height: "30%",
								}}
							>
								<div className="ad-panel-second-column-color">
									<div
										style={{
											backgroundColor: `${props.colorAW20Panel}`,
											borderRadius: 25,
											height: `${props.percentageAvailableWater20}%`,
											width: "100%",
										}}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
