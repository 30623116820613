import { useState } from "react";
import SaveDataButton from "../../data-table-custom/save-data-button";

export default function IrrigationTypeCreate() {
	const [nome, setNome] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						nome: `${nome}`,
					}}
					action={"post"}
					endpoint={"/irriga_api"}
					returnTo={`/irrigation-type-list`}
				/>
			</div>
		</div>
	);
}
