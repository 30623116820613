import React from "react";

import "./alertComponent.css";

export default function AlertComponent({ message, code }) {
	const alertMessage = () => {
		switch (code) {
			case "SUCCESS":
				return (
					<div className="alert-container-success alert">
						{message}
					</div>
				);
			case "LOADING":
				return (
					<div className="alert-container-loading alert">
						{message}
					</div>
				);
			case "ERROR":
				return (
					<div className="alert-container-error alert">{message}</div>
				);

			default:
				return null;
		}
	};

	return <div>{alertMessage()}</div>;
}
