import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";
import moment from "moment";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import thermometerIcon from "../../../../assets/icons/thermometer-icon.png";
import humidityIcon from "../../../../assets/icons/humidity-icon.png";
import windIcon from "../../../../assets/icons/wind-icon.png";
import deltaTIcon from "../../../../assets/icons/delta-t-icon.png";
import grayFlagIcon from "../../../../assets/icons/gray-flag-icon.png";
import greenFlagIcon from "../../../../assets/icons/green-flag-icon.png";
import yellowFlagIcon from "../../../../assets/icons/yellow-flag-icon.png";
import orangeFlagIcon from "../../../../assets/icons/orange-flag-icon.png";
import redFlagIcon from "../../../../assets/icons/red-flag-icon.png";

import "../panel-styles.css";

export default function PulverizationConditionsPanel({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [data, setData] = useState([]);

	let getData = useGet(
		`dados_horarios_consulta?gateway=${
			gateway.id_dispositivo
		}&ultimo_dado=${1}`,
		access_token
	);

	useEffect(() => {
		if (
			!getData.state.loading &&
			!getData.state.error &&
			getData.state.data.length > 0
		) {
			setData({
				Data: getData.state.data[0].Data,
				Tmed: getData.state.data[0].Tmed
					? +getData.state.data[0].Tmed
					: NaN,
				URmed: getData.state.data[0].URmed
					? +getData.state.data[0].URmed
					: NaN,
				Vmed: getData.state.data[0].Vmed
					? +getData.state.data[0].Vmed
					: NaN,
				DeltaT: getData.state.data[0].DeltaT
					? +getData.state.data[0].DeltaT
					: NaN,
				Pulv: getData.state.data[0].Pulv
					? +getData.state.data[0].Pulv
					: NaN,
			});
		}
	}, [getData.state.loading, getData.state.error, getData.state.data]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Recomendação de Pulverização</em>
			</div>

			<div className="panel-content-padding" style={{ height: "350px" }}>
				{getData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error ? (
					<ErrorMessage />
				) : data.length !== 0 ? (
					<div>
						<div className="weather-data-container">
							<img
								src={thermometerIcon}
								height={"30px"}
								width={"30px"}
								alt=""
								style={{ marginRight: "5px" }}
							/>
							<p className="paragraph-style">
								Temperatura do Ar: &nbsp;
							</p>
							{!isNaN(data.Tmed) ? (
								<p className="last-value-text">
									{data.Tmed.toFixed(1)} ºC
								</p>
							) : (
								<p className="last-value-text">-- ºC</p>
							)}
						</div>

						<div className="weather-data-container">
							<img
								src={humidityIcon}
								height={"30px"}
								width={"30px"}
								alt=""
								style={{ marginRight: "5px" }}
							/>
							<p className="paragraph-style">
								Umidade Relativa: &nbsp;
							</p>
							{!isNaN(data.URmed) ? (
								<p className="last-value-text">
									{data.URmed.toFixed(1)} %
								</p>
							) : (
								<p className="last-value-text">-- %</p>
							)}
						</div>

						<div className="weather-data-container">
							<img
								src={windIcon}
								height={"30px"}
								width={"30px"}
								alt=""
								style={{ marginRight: "5px" }}
							/>
							<p className="paragraph-style">
								Velocidade do Vento: &nbsp;
							</p>
							{!isNaN(data.Vmed) ? (
								<p className="last-value-text">
									{data.Vmed.toFixed(1)} km/h
								</p>
							) : (
								<p className="last-value-text">-- km/h</p>
							)}
						</div>

						<div className="weather-data-container">
							<img
								src={deltaTIcon}
								height={"30px"}
								width={"30px"}
								alt=""
								style={{ marginRight: "5px" }}
							/>
							<p className="paragraph-style">Delta T: &nbsp;</p>
							{!isNaN(data.DeltaT) ? (
								<p className="last-value-text">
									{data.DeltaT.toFixed(1)} ºC
								</p>
							) : (
								<p className="last-value-text">-- ºC</p>
							)}
						</div>

						<PulverizationFlag pulv={data.Pulv} />

						<div className="panel-footer-container">
							<p className="panel-footer-text">
								Dados atualizados em{" "}
								{moment(data.Data)
									.utcOffset(-6)
									.format("DD/MM/YY HH:mm")}
							</p>
						</div>
					</div>
				) : (
					<NoDataAvailableMessage />
				)}
			</div>
		</div>
	);
}

function PulverizationFlag({ pulv }) {
	let flagColor, text;

	switch (pulv) {
		case 0:
			flagColor = grayFlagIcon;
			text = "Não há dados para a recomendação de Pulverização!";
			break;
		case 1:
			flagColor = greenFlagIcon;
			text = "Condições favoráveis para a Pulverização!";
			break;
		case 2:
			flagColor = yellowFlagIcon;
			text = "Condições não recomendáveis para a Pulverização...";
			break;
		case 3:
			flagColor = orangeFlagIcon;
			text = "Condições não recomendáveis para a aplicação de Herbicida.";
			break;
		case 4:
			flagColor = yellowFlagIcon;
			text =
				"Temperatura e Umidade Relativa estão favoráveis, mas a Velocidade do Vento tem que estar entre 3.2 km/h e 6.5 km/h!";
			break;
		case 5:
			flagColor = redFlagIcon;
			text = "Condições impróprias para a Pulverização...";
			break;
		default:
			flagColor = grayFlagIcon;
			text = "Não há dados para a recomendação de Pulverização!";
			break;
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				flex: 1,
				marginBottom: "10px",
			}}
		>
			<img
				src={flagColor}
				height={"35px"}
				width={"35px"}
				alt=""
				style={{ marginRight: "5px" }}
			/>
			<p
				style={{
					fontSize: "14px",
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				{text}
			</p>
		</div>
	);
}
