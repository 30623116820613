import noManagementZoneIcon from "../../../../assets/icons/no-management-zone-icon.png";
import "../message-panels-styles.css";

export default function NoManagementZoneMessage() {
	return (
		<div className="message-container">
			<div className="message-content-container">
				<img
					src={noManagementZoneIcon}
					height={"100px"}
					width={"100px"}
					alt=""
				/>
				<span className="message-text-style">
					Nenhuma Zona de Manejo Encontrada...
				</span>
			</div>
		</div>
	);
}
