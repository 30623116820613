import React from "react";
import Plot from "react-plotly.js";

export default function BarPercentageChart({ data, unit, color, fileName }) {
	const values = [
		{
			x: data.map((item) => item.farm),
			y: data.map((item) => item.percentage),
			type: "bar",
			marker: { color: `${color}` },
			text: data.map((d) => `${d.percentage}%`),
			textfont: {
				size: 10,
				color: color,
			},
			textposition: "outside",
			hoverinfo: "x+text",
		},
	];

	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
					rangemode: "tozero",
					range: [0, 100], // Define o limite de 0 a 100
				},
				xaxis: {
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 20, // Margem direita
					t: 20, // Margem superior
					b: 50, // Margem inferior
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "100%",
			}}
		/>
	);
}
