import React from "react";
import Plot from "react-plotly.js";
import moment from "moment";

export default function LineChartNElements({
	ten20,
	ten40,
	ten60,
	data,
	unit,
	color1,
	color2,
	color3,
	fileName,
}) {
	let ten20Data = {},
		ten40Data = {},
		ten60Data = {};

	if (ten20) {
		ten20Data = {
			x: data.map((item) => item.data),
			y: data.map((item) => item.ten20cm),
			name: `${ten20.tag}`,
			mode: "lines",
			line: { color: `${color1}` },
			text: data.map(
				(item, index) =>
					`${moment(item.data).format(
						"DD/MM/YY HH:mm"
					)}<br><br>Ten20cm: ${item.ten20cm} kPa<br>Ten40cm: ${
						data[index]?.ten40cm ?? "--"
					} kPa<br>Ten60cm: ${data[index]?.ten60cm ?? "--"} kPa`
			),
			hoverinfo: "text",
		};
	}

	if (ten40) {
		ten40Data = {
			x: data.map((item) => item.data),
			y: data.map((item) => item.ten40cm),
			name: `${ten40.tag}`,
			mode: "lines",
			line: { color: `${color2}` },
			text: data.map(
				(item, index) =>
					`${moment(item.data).format(
						"DD/MM/YY HH:mm"
					)}<br><br>Ten20cm: ${
						data[index]?.ten20cm ?? "--"
					} kPa <br>Ten40cm: ${item.ten40cm} kPa<br>Ten60cm: ${
						data[index]?.ten60cm ?? "--"
					} kPa`
			),
			hoverinfo: "text",
		};
	}

	if (ten60) {
		ten60Data = {
			x: data.map((item) => item.data),
			y: data.map((item) => item.ten60cm),
			name: `${ten60.tag}`,
			mode: "lines",
			line: { color: `${color3}` },
			text: data.map(
				(item, index) =>
					`${moment(item.data).format(
						"DD/MM/YY HH:mm"
					)}<br><br>Ten20cm: ${
						data[index]?.ten20cm ?? "--"
					} kPa <br>Ten40cm: ${
						data[index]?.ten40cm ?? "--"
					} kPa <br>Ten60cm:${item.ten60cm} kPa`
			),
			hoverinfo: "text",
		};
	}

	const values = [ten20Data, ten40Data, ten60Data];

	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				xaxis: {
					tickformat: "%d/%m/%y %H:%M",
					tickfont: {
						size: 9,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 10, // Margem direita
					t: 30, // Margem superior
					b: 20, // Margem inferior
				},
				hoverlabel: {
					bgcolor: "rgba(51,51,51,255)", // Cor de fundo do tooltip
					font: { color: "white" }, // Cor do texto do tooltip
					align: "left",
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
				showlegend: true,
				legend: {
					orientation: "h",
					xanchor: "center",
					x: 0.5,
					y: 1.4,
				},
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "350px",
			}}
		/>
	);
}
