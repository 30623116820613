import { BASE_URL } from "../services/api";
const requestConfig = (props) => ({
	method: props.method,
	url: `${BASE_URL}${props.endpoint}`,
	headers: {
		Authorization: `Bearer ${props.access_token}`,
		"Content-Type": "application/json",
	},
	params: props.params,
	data: props.data,
});

export default requestConfig;
