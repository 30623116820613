import { useState } from "react";

import SaveDataButton from "../../data-table-custom/save-data-button";

export default function SoilTypeCreate() {
	const [nome, setNome] = useState("");
	const [thetaR20, setThetaR20] = useState("");
	const [thetaR40, setThetaR40] = useState("");
	const [thetaR60, setThetaR60] = useState("");
	const [thetaS20, setThetaS20] = useState("");
	const [thetaS40, setThetaS40] = useState("");
	const [thetaS60, setThetaS60] = useState("");
	const [alpha20, setAlpha20] = useState("");
	const [alpha40, setAlpha40] = useState("");
	const [alpha60, setAlpha60] = useState("");
	const [n20, setN20] = useState("");
	const [n40, setN40] = useState("");
	const [n60, setN60] = useState("");
	const [m20, setM20] = useState("");
	const [m40, setM40] = useState("");
	const [m60, setM60] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={""}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Θr (20cm, 40cm, 60cm)</span>
				<div className="param-container">
					<input
						className="soil-params"
						type="text"
						name="thetaR20"
						value={thetaR20}
						onChange={(e) => setThetaR20(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="thetaR40"
						value={thetaR40}
						onChange={(e) => setThetaR40(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="thetaR60"
						value={thetaR60}
						onChange={(e) => setThetaR60(e.target.value)}
					/>
				</div>
			</div>
			<div className="admin-container">
				<span className="admin-title">Θs (20cm, 40cm, 60cm)</span>
				<div className="param-container">
					<input
						className="soil-params"
						type="text"
						name="thetaS20"
						value={thetaS20}
						onChange={(e) => setThetaS20(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="thetaS40"
						value={thetaS40}
						onChange={(e) => setThetaS40(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="thetaS60"
						value={thetaS60}
						onChange={(e) => setThetaS60(e.target.value)}
					/>
				</div>
			</div>
			<div className="admin-container">
				<span className="admin-title">α (20cm, 40cm, 60cm)</span>
				<div className="param-container">
					<input
						className="soil-params"
						type="text"
						name="alpha20"
						value={alpha20}
						onChange={(e) => setAlpha20(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="alpha40"
						value={alpha40}
						onChange={(e) => setAlpha40(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="alpha60"
						value={alpha60}
						onChange={(e) => setAlpha60(e.target.value)}
					/>
				</div>
			</div>
			<div className="admin-container">
				<span className="admin-title">n (20cm, 40cm, 60cm)</span>
				<div className="param-container">
					<input
						className="soil-params"
						type="text"
						name="n20"
						value={n20}
						onChange={(e) => setN20(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="n40"
						value={n40}
						onChange={(e) => setN40(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="n60"
						value={n60}
						onChange={(e) => setN60(e.target.value)}
					/>
				</div>
			</div>
			<div className="admin-container">
				<span className="admin-title">m (20cm, 40cm, 60cm)</span>
				<div className="param-container">
					<input
						className="soil-params"
						type="text"
						name="m20"
						value={m20}
						onChange={(e) => setM20(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="m40"
						value={m40}
						onChange={(e) => setM40(e.target.value)}
					/>
					<span>&nbsp;;&nbsp;</span>
					<input
						className="soil-params"
						type="text"
						name="m60"
						value={m60}
						onChange={(e) => setM60(e.target.value)}
					/>
				</div>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						nome: `${nome}`,
						theta_s_0_20: `${+thetaS20}`,
						theta_s_20_40: `${+thetaS40}`,
						theta_s_40_60: `${+thetaS60}`,
						theta_r_0_20: `${+thetaR20}`,
						theta_r_20_40: `${+thetaR40}`,
						theta_r_40_60: `${+thetaR60}`,
						alpha_0_20: `${+alpha20}`,
						alpha_20_40: `${+alpha40}`,
						alpha_40_60: `${+alpha60}`,
						n_solo_0_20: `${+n20}`,
						n_solo_20_40: `${+n40}`,
						n_solo_40_60: `${+n60}`,
						m_solo_0_20: `${+m20}`,
						m_solo_20_40: `${+m40}`,
						m_solo_40_60: `${+m60}`,
					}}
					action={"post"}
					endpoint={`/solo_api`}
					returnTo={`/soils`}
				/>
			</div>
		</div>
	);
}
