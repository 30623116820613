import React from "react";

import SelectDropdown from "../../select-dropdown";
import ZMIcon from "../../../assets/icons/management-zones-icon.png";
import PinIcon from "../../../assets/icons/pin-location-icon.png";

export default function PanelHeader({
	componentsList,
	chosenComponent,
	setChosenComponent,
	titleHeader,
	imageType,
}) {
	let icon;
	switch (imageType) {
		case "management-zones":
			icon = (
				<img
					src={ZMIcon}
					height={"30px"}
					alt=""
					style={{ marginRight: "5px" }}
				/>
			);
			break;
		// Galpao
		case "shed":
			icon = (
				<img
					src={PinIcon}
					height={"30px"}
					alt=""
					style={{ marginRight: "5px" }}
				/>
			);
			break;
		// Poço
		case "pit":
			icon = (
				<img
					src={PinIcon}
					height={"30px"}
					alt=""
					style={{ marginRight: "5px" }}
				/>
			);
			break;
		default:
			break;
	}

	return (
		<div className="header-dashboard">
			<div
				className="header-dashboard-item"
				style={{ alignItems: "center", display: "flex" }}
			>
				{icon}
				{/* <span className="header-dashboard-text"> */}
				<span style={{ fontSize: "12px", fontWeight: "bold" }}>
					{titleHeader}
				</span>
			</div>

			<div
				className="header-dashboard-item"
				style={{ margin: "-10px 0px 0px 0px" }}
			>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={componentsList}
					selectedValue={chosenComponent}
					setSelectedValue={setChosenComponent}
					width={"200px"}
				/>
			</div>
		</div>
	);
}
