import React from "react";
import Plot from "react-plotly.js";
import { UR_deltaT } from "../../../lib/ur-delta-t-matriz";

export default function AreaChartDeltaT({ data, fileName }) {
	const xValues = Array.from({ length: 41 }, (_, i) => i);
	const yValues = Array.from({ length: 101 }, (_, i) => 100 - i).reverse();
	const UR = UR_deltaT;

	// Inicializa uma matriz C preenchida com zeros
	const C = Array.from({ length: 101 }, () => Array(41).fill(0));

	for (let t = 0; t <= 40; t++) {
		for (let tw = 2; tw <= 19; tw++) {
			const p = UR[t][tw - 2];
			for (let i = 0; i < p; i++) {
				C[i][t] += 0.5;
			}
		}
	}

	const values = [
		{
			x: xValues,
			y: yValues,
			z: C,
			type: "heatmap",
			colorscale: "Viridis",
			hoverinfo: "none",
		},
		{
			x: [data.Tmed],
			y: [data.URmed],
			mode: "markers",
			type: "scatter",
			marker: {
				size: 10,
				color: "red",
			},
			text: [`Delta T = ${data.DeltaT}ºC`],
			name: "", // Remove o Trace 1 do tooltip
		},
	];

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Plot
				data={values}
				layout={{
					xaxis: { title: "Temp (ºC)", fixedrange: true },
					yaxis: { title: "UR (%)", fixedrange: true },
					autosize: true,
					margin: {
						l: 50, // Margem esquerda
						// r: 5, // Margem direita
						t: 40, // Margem superior
						b: 70, // Margem inferior
					},
					hoverlabel: {
						bgcolor: "#e74b39", // Cor de fundo do tooltip
						font: { color: "white" }, // Cor do texto do tooltip
					},
					plot_bgcolor: "rgba(0,0,0,0)",
					paper_bgcolor: "rgb(250, 249, 249)",
				}}
				config={{
					modeBarButtonsToRemove: [
						"pan2d",
						"select2d",
						"lasso2d",
						"resetScale2d",
						"zoomOut2d",
					],
					displaylogo: false,
					toImageButtonOptions: {
						filename: fileName,
						format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
					},
				}}
				style={{
					width: "90%",
				}}
			/>
		</div>
	);
}
