import { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../../reducers/useGet';
import AuthService from '../../../services/auth';

import DataTable from 'react-data-table-component';
import AddButton from '../../../components/data-table-custom/add-button';
import SearchBar from '../../../components/data-table-custom/search-bar';
import ExportButton from '../../../components/data-table-custom/export-button';
import downloadCSV from '../../../scripts/export-script';
import { paginationComponentOptions } from '../../../components/data-table-custom/dataTableCustom';
import ErrorMessage from '../../dashboard/messages-panel/error-message';

export default function ManagementZonesList() {
	const user = AuthService.getCurrentUser();

	let zmRequest, gtwRequest, orgRequest, dlRequest;

	switch (user.nivel) {
		case 2:
			zmRequest = `zona_manejo_consulta?zona_manejo_representante=${user.organizacao}`;
			gtwRequest = `gateway_consulta?gateway_representante=${user.organizacao}`;
			orgRequest = `organizacao_consulta?organi_representante=${user.organizacao}`;
			dlRequest = `coletor_consulta?coletor_representante=${user.organizacao}`;
			break;

		case 3:
			zmRequest = 'zonas_manejo_api';
			gtwRequest = 'gateway_api';
			orgRequest = 'organizacao_api';
			dlRequest = 'coletor_api';
			break;
		default:
			zmRequest = `zona_manejo_consulta?zona_manejo_organizacao=${user.organizacao}`;
			gtwRequest = `gateway_consulta?gateway_organi=${user.organizacao}`;
			orgRequest = `organizacao_consulta?organi_id=${user.organizacao}`;
			dlRequest = `coletor_consulta?coletor_organizacao=${user.organizacao}`;
			break;
	}

	let getZonasManejo = useGet(zmRequest, user.access_token);
	let getGateways = useGet(gtwRequest, user.access_token);
	let getOrganizations = useGet(orgRequest, user.access_token);
	let getDataloggers = useGet(dlRequest, user.access_token);
	let irrigationTypeList = useGet('irriga_api', user.access_token);
	let cultivationList = useGet('cultura_api', user.access_token);
	let soilList = useGet('solo_api', user.access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [gatewaysNameList, setGatewaysNameList] = useState(null);
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [dataloggersNameList, setDataloggersNameList] = useState(null);
	const [irrigationNameList, setIrrigationNameList] = useState(null);
	const [cultivationNameList, setCultivationNameList] = useState(null);
	const [soilNameList, setSoilNameList] = useState(null);

	useEffect(() => {
		if (
			!getZonasManejo.state.loading &&
			!getZonasManejo.state.error &&
			!getOrganizations.state.loading &&
			!getOrganizations.state.error &&
			!getGateways.state.loading &&
			!getGateways.state.error &&
			!getDataloggers.state.loading &&
			!getDataloggers.state.error &&
			!irrigationTypeList.state.loading &&
			!irrigationTypeList.state.error &&
			!cultivationList.state.loading &&
			!cultivationList.state.error &&
			!soilList.state.loading &&
			!soilList.state.error
		) {
			setData(getZonasManejo.state.data);
			setOrganizationsNameList(data);

			let gtw_names_id = getGateways.state.data.map((d) => {
				return {
					id: d.id,
					nome: d.tag,
					id_dispositivo: d.id_dispositivo,
				};
			});

			let org_names_id = getOrganizations.state.data.map((d) => {
				return {
					id: d.id,
					nome: d.nome,
				};
			});

			let dl_names_id = getDataloggers.state.data.map((d) => {
				return {
					id: d.id,
					nome: d.tag,
					id_dispositivo: d.id_dispositivo,
					guid_dispositivo: d.guid_dispositivo,
				};
			});

			setRecords(data);
			setGatewaysNameList(gtw_names_id);
			setOrganizationsNameList(org_names_id);
			setDataloggersNameList(dl_names_id);
			setIrrigationNameList(irrigationTypeList.state.data);
			setCultivationNameList(cultivationList.state.data);
			setSoilNameList(soilList.state.data);
		}
	}, [
		data,
		getZonasManejo.state.loading,
		getZonasManejo.state.error,
		getZonasManejo.state.data,
		getOrganizations.state.loading,
		getOrganizations.state.error,
		getOrganizations.state.data,
		getGateways.state.loading,
		getGateways.state.error,
		getGateways.state.data,
		getDataloggers.state.loading,
		getDataloggers.state.error,
		getDataloggers.state.data,
		irrigationTypeList.state.loading,
		irrigationTypeList.state.error,
		irrigationTypeList.state.data,
		cultivationList.state.loading,
		cultivationList.state.error,
		cultivationList.state.data,
		soilList.state.loading,
		soilList.state.error,
		soilList.state.data,
	]);

	const columns = [
		{
			name: 'ID',
			selector: (row) => row.id,
			sortable: true,
		},
		{ name: 'Nome', selector: (row) => row.nome, sortable: true },
		{
			name: 'Organização',
			selector: (row) => {
				let org = organizationsNameList.find((el) => el.id === row.organizacao);
				if (org === undefined) return '';
				else return org.nome;
			},
			sortable: true,
		},
		{
			name: 'Estação PalmaFlex',
			selector: (row) => {
				let gtw = gatewaysNameList.find((el) => el.id === row.concentrador);
				if (gtw === undefined) return '';
				else return gtw.nome;
			},
			sortable: true,
		},
	];

	const openManagementZoneDetail = (managementZone) => {
		Navigate(`/management-zones/${managementZone.id}`, {
			state: {
				managementZone,
				organizationsNameList,
				gatewaysNameList,
				dataloggersNameList,
				irrigationNameList,
				cultivationNameList,
				soilNameList,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{getZonasManejo.state.loading &&
			getOrganizations.state.loading &&
			getGateways.state.loading &&
			getDataloggers.state.loading &&
			irrigationTypeList.state.loading &&
			cultivationList.state.loading &&
			soilList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getZonasManejo.state.error ||
			  getOrganizations.state.error ||
			  getGateways.state.error ||
			  getDataloggers.state.error ||
			  irrigationTypeList.state.error ||
			  cultivationList.state.error ||
			  soilList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							table={'manegementZones'}
							setRecords={setRecords}
							data={data}
						/>
						<Export onExport={() => downloadCSV(data, 'zonasManejo')} />
						{user.nivel !== 0 ? (
							<AddButton
								goTo={'/management-zones/add-new-management-zone'}
								list={{
									organizationsNameList,
									gatewaysNameList,
									dataloggersNameList,
									irrigationNameList,
									cultivationNameList,
									soilNameList,
								}}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Zona de Manejo encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(zmData) =>
							user.nivel !== 0 ? openManagementZoneDetail(zmData) : null
						}
					/>
				</div>
			)}
		</div>
	);
}
