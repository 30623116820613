import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { deviceStatusItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";

import { generateRandomToken } from "../../../lib/generateRandomToken";

export default function GatewayCreate() {
	const { state } = useLocation();

	const [id, setId] = useState("");
	const [tag, setTag] = useState("");
	const [organizacao, setOrganizacao] = useState(null);
	const [numeroSerie, setNumeroSerie] = useState("");
	const [status, setStatus] = useState(null);
	const [checkbox, setCheckbox] = useState(false);
	const [modeloGateway, setModeloGateway] = useState("");
	const [firmware, setFirware] = useState("");
	const [hardware, setHardware] = useState("");

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID *</span>
				<input
					className="admin-input"
					type="text"
					name="id"
					value={id}
					onChange={(e) => setId(e.target.value.toUpperCase())}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Tag * </span>
				<input
					className="admin-input"
					type="text"
					name="tag"
					value={tag}
					onChange={(e) => setTag(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Número de Série * </span>
				<input
					className="admin-input"
					type="text"
					name="numeroserie"
					value={numeroSerie}
					onChange={(e) => setNumeroSerie(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Organização</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={state.list}
					selectedValue={organizacao}
					setSelectedValue={setOrganizacao}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<span className="admin-title">Status</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={deviceStatusItems}
					selectedValue={status}
					setSelectedValue={setStatus}
					width={"305px"}
				/>
			</div>

			<div className="admin-container">
				<div className="checkbox-container">
					<input
						className="checkbox-style"
						type="checkbox"
						checked={checkbox}
						onChange={(e) => setCheckbox(e.target.checked)}
					/>
					<em className="checkbox-text">Fazer a Previsão do Tempo</em>
				</div>
			</div>

			<div className="admin-container">
				<span className="admin-title">Sobre o Concentrador</span>
			</div>

			<div className="admin-container">
				<em className="item-text">Modelo do Dispositivo</em>
				<input
					className="admin-input"
					type="text"
					name="modelo"
					value={modeloGateway}
					onChange={(e) => setModeloGateway(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Versão do Firmware</em>
				<input
					className="admin-input"
					type="text"
					name="firmware"
					value={firmware}
					onChange={(e) => setFirware(e.target.value)}
				/>
			</div>

			<div className="admin-container">
				<em className="item-text">Versão do Hardware</em>
				<input
					className="admin-input"
					type="text"
					name="hardware"
					value={hardware}
					onChange={(e) => setHardware(e.target.value)}
				/>
			</div>

			<div className="footer">
				<SaveDataButton
					data={{
						organi_concentra_id: `${
							organizacao ? organizacao.id : null
						}`,
						id_dispositivo: `${id}`,
						tag: `${tag}`,
						serie: `${numeroSerie}`,
						modelo: `${modeloGateway}`,
						firmware: `${firmware}`,
						hardware: `${hardware}`,
						status: status && status.nome === "Ativo" ? "on" : null,
						previsao: `${checkbox ? "on" : null}`,
						GUID: `${generateRandomToken(8)}-${generateRandomToken(
							4
						)}-${generateRandomToken(4)}-${generateRandomToken(
							4
						)}-${generateRandomToken(12)}`,
					}}
					action={"post"}
					endpoint={`/gateway_api`}
					returnTo={`/gateways`}
				/>
			</div>
		</div>
	);
}
