import { useNavigate } from "react-router-dom";

import "./dataTableCustom.css";
import { BiEditAlt } from "react-icons/bi";

export default function EditItemButton({ data, goTo }) {
	const Navigate = useNavigate();

	const handleButtonClick = () => {
		Navigate(`${goTo}`, {
			state: {
				data,
			},
		});
	};

	return (
		<div>
			<button
				className="data-table-edit-item-button-custom"
				onClick={handleButtonClick}
			>
				<BiEditAlt size={"16px"} className="icon-style" />
				Editar
			</button>
		</div>
	);
}
