import React from "react";
import Plot from "react-plotly.js";

export default function LineChart1ElementAndArea({
	data1,
	data2,
	unit,
	color1,
	color2,
	legend1,
	legend2,
	fileName,
}) {
	const values = [
		{
			x: data1.map((item) => item.timestamp),
			y: data1.map((item) => data2),
			name: legend2,
			mode: "lines",
			line: { color: color2 },
			fill: "tozeroy",
			fillcolor: "rgba(255, 0, 0, 0.2)",
			hoverinfo: "none",
		},
		{
			x: data1.map((item) => item.timestamp),
			y: data1.map((item) => item.value),
			type: "scatter",
			name: legend1,
			line: { color: `${color1}` },
			text: data1.map((d) => d.value.toFixed(1)),
			hoverinfo: "x+text",
		},
	];
	return (
		<Plot
			data={values}
			layout={{
				yaxis: {
					title: {
						text: `${unit}`,
						font: {
							size: 10,
							color: "rgba(83,107,123,255)",
						},
					},
					fixedrange: true,
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
					range: [0, 105],
				},
				xaxis: {
					tickformat: "%d/%m/%y",
					tickfont: {
						size: 10,
						color: "rgba(83,107,123,255)",
					},
					showline: true,
					linecolor: "rgba(144,164,174,255)",
					linewidth: 2,
				},
				dragmode: false, // Desabilita o pan
				zoommode: false, // Desabilita o zoom
				autosize: true,
				margin: {
					l: 30, // Margem esquerda
					r: 10, // Margem direita
					t: 0, // Margem superior
					b: 40, // Margem inferior
				},
				hoverlabel: {
					font: { color: "white" }, // Cor do texto do tooltip
					align: "left",
				},
				plot_bgcolor: "rgba(0,0,0,0)",
				paper_bgcolor: "rgb(250, 249, 249)",
				showlegend: true,
				legend: {
					orientation: "h",
					xanchor: "center",
					x: 0.5,
					y: 1.2,
				},
			}}
			config={{
				modeBarButtonsToRemove: [
					"pan2d",
					"select2d",
					"lasso2d",
					"resetScale2d",
					"zoomOut2d",
					"zoom2d",
					"zoomIn2d",
					"autoScale2d",
				],
				displaylogo: false,
				toImageButtonOptions: {
					filename: fileName,
					format: "png", // Pode ser 'png', 'jpeg', 'webp' ou 'svg'
				},
			}}
			style={{
				width: "100%",
				height: "350px",
			}}
		/>
	);
}

// import {
// 	VictoryLine,
// 	VictoryChart,
// 	VictoryAxis,
// 	VictoryTheme,
// 	VictoryArea,
// 	VictoryLegend,
// } from "victory";
// import moment from "moment";

// export default function LineChart1ElementAndArea({
// 	data1,
// 	data2,
// 	unit,
// 	color1,
// 	color2,
// 	legend1,
// 	legend2,
// }) {
// 	return (
// 		<div>
// 			<VictoryChart
// 				height={250}
// 				theme={VictoryTheme.material}
// 				domainPadding={{ y: 10 }}
// 				padding={{
// 					top: 20,
// 					bottom: 40,
// 					left: 45,
// 					right: 30,
// 				}}
// 			>
// 				<VictoryAxis
// 					style={{
// 						tickLabels: {
// 							fontSize: 10,
// 							angle: -20,
// 							padding: 10,
// 						},
// 					}}
// 					tickCount={4}
// 					tickFormat={(t) => moment(t).format("DD/MM HH:mm")}
// 				/>

// 				<VictoryAxis
// 					dependentAxis
// 					label={`${unit}`}
// 					style={{
// 						axisLabel: { fontSize: 10, padding: 30 },
// 						tickLabels: { fontSize: 10, padding: 2 },
// 					}}
// 					tickFormat={(t) => t.toFixed(1)}
// 				/>

// 				<VictoryLine
// 					data={data1}
// 					x="timestamp"
// 					y="value"
// 					interpolation="monotoneX"
// 					style={{ data: { stroke: `${color1}` } }}
// 				/>

// 				<VictoryArea
// 					style={{
// 						data: {
// 							fill: "red",
// 							fillOpacity: 0.2,
// 							stroke: `${color2}`,
// 						},
// 					}}
// 					y={() => data2}
// 				/>

// 				<VictoryLegend
// 					x={100}
// 					orientation="horizontal"
// 					style={{ border: { stroke: "transparent" } }}
// 					colorScale={[`${color1}`, `${color2}`]}
// 					data={[{ name: `${legend1}` }, { name: `${legend2}` }]}
// 				/>
// 			</VictoryChart>
// 		</div>
// 	);
// }
