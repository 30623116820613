import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import requestConfig from "../../reducers/request_config";

import AlertComponent from "../alert-component/alertComponent";
import { FiSave } from "react-icons/fi";

import "./dataTableCustom.css";

export default function SaveUserAndSendEmailButton(props) {
	const Navigate = useNavigate();
	const [error, setError] = useState("");
	const [response, setResponse] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);

	const [showAlert, setShowAlert] = useState(false);
	const { access_token } = AuthService.getCurrentUser();

	const handleSaveData = async (e) => {
		e.preventDefault();

		let resp_send_email;

		try {
			const resp = await axios.request(
				requestConfig({
					method: props.action1,
					endpoint: props.endpoint1,
					access_token: access_token,
					data: props.data,
				})
			);

			setResponse(resp);
			setShowAlert(true);

			if (resp.status === 201) {
				resp_send_email = await axios.request(
					requestConfig({
						method: props.action2,
						endpoint: props.endpoint2,
						access_token: "",
						params: {
							tipo_email: "registrar",
							user_email: props.data.email,
							user_nome: props.data.nome,
						},
						data: [],
					})
				);
			}

			setResponse(resp_send_email);
			setShowAlert(true);
		} catch (e) {
			setErrorStatus(true);
			setError(e.message);
			setShowAlert(true);
		}

		setTimeout(() => {
			setShowAlert(false);
			Navigate(props.returnTo);
		}, 5000);
	};

	return (
		<div>
			<button
				className="data-table-button-custom"
				onClick={handleSaveData}
			>
				<FiSave size={"16px"} className="icon-style" />
				Salvar
			</button>
			{response.status === 201 && showAlert ? (
				<AlertComponent
					message={"Usuário Cadastrado com Sucesso!"}
					code={"SUCCESS"}
				/>
			) : null}
			{errorStatus && showAlert ? (
				<AlertComponent message={error} code={"ERROR"} />
			) : null}
			{response.status === 200 && showAlert ? (
				<AlertComponent message={"E-mail enviado!"} code={"SUCCESS"} />
			) : null}
		</div>
	);
}
