import pivotIcon from "../../../../../assets/icons/pivot-percentimeter-icon.png";
import "../../panel-styles.css";

export default function PercentimeterPanel({ data }) {
	let panelText = "";

	if (data.r_perc) {
		if (data.r_perc > 100) panelText = "Não Irrigar!";
		else panelText = data.r_perc.toFixed(0) + "%";
	}

	return (
		<div
			className="alerts-container"
			style={{ width: "270px", height: "110px", marginBottom: "10px" }}
		>
			<div className="alerts-title-container">
				<span className="alerts-panel-title">
					Regulagem do Percentímetro
				</span>
			</div>
			<div className="it-content-container">
				<img
					src={pivotIcon}
					height={"60px"}
					alt=""
					style={{ margin: "0px 15px 0px 20px" }}
				/>

				{data.r_perc ? (
					<span className="it-text-style">{panelText}</span>
				) : (
					<span className="it-text-style">
						Nenhum dado disponível
					</span>
				)}
			</div>
		</div>
	);
}
