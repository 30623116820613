import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthService from "../../services/auth";
import Navbar from "../navbar";
import Dashboard from "../dashboard";
import { DeviceTree } from "./device-tree";

import { GatewayList, GatewayEdit, GatewayCreate } from "./gateways";
import {
	DataloggerList,
	DataloggerEdit,
	DataloggerCreate,
} from "./dataloggers";
import { SensorList, SensorEdit, SensorCreate } from "./sensors";
import {
	ManagementZonesList,
	ManagementZonesEdit,
	ManagementZonesCreate,
} from "./management-zones";
import {
	DeviceModelList,
	DeviceModelEdit,
	DeviceModelCreate,
} from "./sensors-models";
import {
	SensorTypeList,
	SensorTypeEdit,
	SensorTypeCreate,
} from "./sensors-type";
import DeviceStatus from "./device-status";
import { UserList, UserEdit, UserCreate } from "./users";
import {
	OrganizationList,
	OrganizationEdit,
	OrganizationCreate,
} from "./organizations";
import {
	IrrigationTypeList,
	IrrigationTypeEdit,
	IrrigationTypeCreate,
} from "./irrigation-type";
import {
	CultivationTypeList,
	CultivationTypeCreate,
	CultivationTypeEdit,
} from "./cultivation-type";
import {
	PhenologicalStagesList,
	PhenologicalStagesEdit,
	PhenologicalStagesCreate,
} from "./cultivation-type/phenological-stages";
import { SoilTypeList, SoilTypeEdit, SoilTypeCreate } from "./soil-type";

import "./admin-styles.css";

function Admin() {
	const { nivel } = AuthService.getCurrentUser();

	const [showAllSidebar, setShowAllSidebar] = useState(true);
	const contentStyle = {
		marginLeft: showAllSidebar ? "90px" : "270px",
		transition: "margin 0.2s ease",
	};

	return (
		<div>
			<Navbar
				showAllSidebar={showAllSidebar}
				setShowAllSidebar={setShowAllSidebar}
			/>
			<div style={contentStyle}>
				<Routes>
					<Route path="/dashboard" element={<Dashboard />} exact />
					<Route path="/device-tree" element={<DeviceTree />} />
					<Route path="/gateways" element={<GatewayList />} />
					<Route
						path="/gateways/:id"
						element={
							nivel === 3 ? (
								<GatewayEdit />
							) : (
								<Navigate to="/gateways" />
							)
						}
					/>
					<Route
						path="/gateways/add-new-gateway"
						element={
							nivel === 3 ? (
								<GatewayCreate />
							) : (
								<Navigate to="/gateways" />
							)
						}
					/>
					<Route path="/dataloggers" element={<DataloggerList />} />
					<Route
						path="/dataloggers/:id"
						element={
							nivel === 3 ? (
								<DataloggerEdit />
							) : (
								<Navigate to="/dataloggers" />
							)
						}
					/>
					<Route
						path="/dataloggers/add-new-datalogger"
						element={
							nivel === 3 ? (
								<DataloggerCreate />
							) : (
								<Navigate to="/dataloggers" />
							)
						}
					/>
					<Route path="/sensors" element={<SensorList />} />
					<Route
						path="/sensors/:id"
						element={
							nivel === 3 ? (
								<SensorEdit />
							) : (
								<Navigate to="/sensors" />
							)
						}
					/>
					<Route
						path="/sensors/add-new-sensor"
						element={
							nivel === 3 ? (
								<SensorCreate />
							) : (
								<Navigate to="/sensors" />
							)
						}
					/>
					<Route
						path="/management-zones"
						element={<ManagementZonesList />}
					/>
					<Route
						path="/management-zones/:id"
						element={
							nivel !== 0 ? (
								<ManagementZonesEdit />
							) : (
								<Navigate to="/management-zones" />
							)
						}
					/>
					<Route
						path="/management-zones/add-new-management-zone"
						element={
							nivel !== 0 ? (
								<ManagementZonesCreate />
							) : (
								<Navigate to="/management-zones" />
							)
						}
					/>
					<Route path="/device-status" element={<DeviceStatus />} />
					<Route
						path="/device-models"
						element={
							nivel === 3 ? (
								<DeviceModelList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/device-models/:id"
						element={
							nivel === 3 ? (
								<DeviceModelEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/device-models/add-new-device-model"
						element={
							nivel === 3 ? (
								<DeviceModelCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/sensor-type"
						element={
							nivel === 3 ? (
								<SensorTypeList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/sensor-type/:id"
						element={
							nivel === 3 ? (
								<SensorTypeEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/sensor-type/add-new-sensor-type"
						element={
							nivel === 3 ? (
								<SensorTypeCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/organizations"
						element={
							nivel === 3 ? (
								<OrganizationList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/organizations/:id"
						element={
							nivel === 3 ? (
								<OrganizationEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/organizations/add-new-organization"
						element={
							nivel === 3 ? (
								<OrganizationCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>

					<Route
						path="/users"
						element={
							nivel === 3 ? (
								<UserList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>

					<Route path="/users/:id" element={<UserEdit />} />
					<Route
						path="/users/add-new-user"
						element={
							nivel === 3 ? (
								<UserCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/irrigation-type-list"
						element={
							nivel === 3 ? (
								<IrrigationTypeList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/irrigation-type-list/:id"
						element={
							nivel === 3 ? (
								<IrrigationTypeEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/irrigation-type-list/add-new-irrigation-type"
						element={
							nivel === 3 ? (
								<IrrigationTypeCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list"
						element={
							nivel === 3 ? (
								<CultivationTypeList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list/:id"
						element={
							nivel === 3 ? (
								<CultivationTypeEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list/add-new-cultivation"
						element={
							nivel === 3 ? (
								<CultivationTypeCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages"
						element={
							nivel === 3 ? (
								<PhenologicalStagesList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages/:id"
						element={
							nivel === 3 ? (
								<PhenologicalStagesEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/cultivations-list/:id/phenological-stages/add-new-phenological-stage"
						element={
							nivel === 3 ? (
								<PhenologicalStagesCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/soils"
						element={
							nivel === 3 ? (
								<SoilTypeList />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/soils/:id"
						element={
							nivel === 3 ? (
								<SoilTypeEdit />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
					<Route
						path="/soils/add-new-soil"
						element={
							nivel === 3 ? (
								<SoilTypeCreate />
							) : (
								<Navigate to="/dashboard" />
							)
						}
					/>
				</Routes>
			</div>
		</div>
	);
}

export default Admin;
