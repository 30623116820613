import React, { useState, useEffect } from "react";
import { useGet } from "../../../reducers/useGet";
import ReactLoading from "react-loading";

import AuthService from "../../../services/auth";

import DashboardButtons from "../dashboard-buttons";

import ShedButtonData from "./shed-button-data";
import PitButtonData from "./pit-button-data";

export default function AnimalDashboard({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [sensorsType, setSensorsType] = useState([]);

	const [button1, setButton1] = useState(false); // Para Galpão
	const [button2, setButton2] = useState(false); // Para Poço

	let getTipoSensores = useGet("tipo_sensor_api", access_token);

	useEffect(() => {
		if (!getTipoSensores.state.loading) {
			setSensorsType(getTipoSensores.state.data);
		}
	}, [getTipoSensores.state.loading, getTipoSensores.state.data]);

	return (
		<div>
			{getTipoSensores.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : (
				<div>
					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"galpao"}
							buttonNumber={button1}
							setButtonNumber={setButton1}
							buttonTitle={"Galpões"}
							reference={gateway}
						/>
						{button1 ? (
							<ShedButtonData
								gatewayID={gateway.id}
								sensorsType={sensorsType}
							/>
						) : null}
					</div>

					<div className="dropdown-button-container">
						<DashboardButtons
							buttonType={"pocos"}
							buttonNumber={button2}
							setButtonNumber={setButton2}
							buttonTitle={"Poços"}
							reference={gateway}
						/>
						{button2 ? (
							<PitButtonData
								gateway={gateway}
								sensorsType={sensorsType}
							/>
						) : null}
					</div>
				</div>
			)}
		</div>
	);
}
