import React, { useState, useEffect } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import ErrorMessage from "../../messages-panel/error-message";
import TankLevelDataIndustryPanel from "../../panel/tank-level-data-insustry-panel";
import {
  TankLevelPanel,
  TankLevelDistributionPanel,
} from "../../panel/tank-panels";

import { tankLevel } from "../../../../sensors-type";
import FertigationPanel from "../../panel/fertigation-panel/fertigation-panel";

export default function FertigationButtonData({ gateway, sensorsType }) {
  let { access_token } = AuthService.getCurrentUser();
  const [devices, setDevices] = useState([]);

  let getColetores = useGet(
    `coletor_consulta?coletor_gateway=${gateway.id}`,
    access_token
  );
  console.log(getColetores.state.data);

  let getSensors = useGet(
    `sensor_consulta?sensor_concentrador=${gateway.id}`,
    access_token
  );

  let getDeviceModels = useGet("modelo_api", access_token);
  console.log(getDeviceModels.state.data);

  useEffect(() => {
    if (
      !getDeviceModels.state.loading &&
      !getDeviceModels.state.error &&
      !getColetores.state.loading &&
      !getColetores.state.error &&
      !getSensors.state.loading &&
      !getSensors.state.error
    ) {
      let sensors = getSensors.state.data.map((s) => {
        let type = sensorsType.filter((el) => s.tipo === el.id);

        if (type.length > 0) {
          s.tipo = type[0].nome;
        }

        // Adicionando o modelo ao sensor
        let model = getDeviceModels.state.data.find(
          (model) => model.id === s.modelo
        );
        if (model) {
          s.modelo = model; // Aqui você pode substituir ou adicionar uma nova propriedade, como s.modeloNome = model.nome;
        }

        return s;
      });

      let devicesList = getColetores.state.data.map((d) => {
        let sensor = sensors.filter(
          (s) => d.id === s.coletor && s.tipo === tankLevel
        );

        if (sensor.length > 0) {
          return { datalogger: d, sensorsList: sensor[0] };
        }

        return null;
      });

      devicesList = devicesList.filter((d) => d !== null);

      console.log(devicesList);
      setDevices(devicesList);
    }
  }, [
    getDeviceModels.state.loading,
    getDeviceModels.state.error,
    getDeviceModels.state.data,
    getColetores.state.loading,
    getColetores.state.error,
    getColetores.state.data,
    getSensors.state.loading,
    getSensors.state.error,
    getSensors.state.data,
    sensorsType,
  ]);

  return (
    <div className="dropdown-button-line">
      {getColetores.state.loading || getSensors.state.loading ? (
        <div className="loading-container">
          <ReactLoading
            type="bubbles"
            color="var(--main-color)"
            width="50px"
            height="50px"
          />
        </div>
      ) : getColetores.state.error || getSensors.state.error ? (
        <ErrorMessage />
      ) : devices.length > 0 ? (
        <div className="show-data-container" style={{ padding: "10px" }}>
          <div className="grid-container-fertigation">
            <TankLevelPanel devices={devices} />
            <TankLevelDistributionPanel devices={devices}/>
          </div>
        </div>
      ) : (
        <NoDeviceConnectedMessage />
      )}
    </div>
  );
}
