import React, { useState, useEffect } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import ErrorMessage from "../../messages-panel/error-message";
import TankLevelDataIndustryPanel from "../../panel/tank-level-data-insustry-panel";

import { tankLevel } from "../../../../sensors-type";

export default function WaterTankButtonData({ gateway, sensorsType }) {
	let { access_token } = AuthService.getCurrentUser();
	const [devices, setDevices] = useState([]);

	let getColetores = useGet(
		`coletor_consulta?coletor_gateway=${gateway.id}`,
		access_token
	);

	let getSensors = useGet(
		`sensor_consulta?sensor_concentrador=${gateway.id}`,
		access_token
	);

	useEffect(() => {
		if (
			!getColetores.state.loading &&
			!getColetores.state.error &&
			!getSensors.state.loading &&
			!getSensors.state.error
		) {
			let sensors = getSensors.state.data.map((s) => {
				let type = sensorsType.filter((el) => s.tipo === el.id);

				if (type.length > 0) {
					s.tipo = type[0].nome;
				}
				return s;
			});

			let devicesList = getColetores.state.data.map((d) => {
				let sensor = sensors.filter(
					(s) => d.id === s.coletor && s.tipo === tankLevel
				);

				if (sensor.length > 0) {
					return { datalogger: d, sensorsList: sensor[0] };
				}

				return null;
			});

			devicesList = devicesList.filter((d) => d !== null);

			setDevices(devicesList);
		}
	}, [
		getColetores.state.loading,
		getColetores.state.error,
		getColetores.state.data,
		getSensors.state.loading,
		getSensors.state.error,
		getSensors.state.data,
		sensorsType,
	]);

	return (
		<div className="dropdown-button-line">
			{getColetores.state.loading || getSensors.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getColetores.state.error || getSensors.state.error ? (
				<ErrorMessage />
			) : devices.length > 0 ? (
				<div
					className="show-data-container"
					style={{ padding: "10px" }}
				>
					<div className="grid-container">
						{devices.map((d) => (
							<div key={d.datalogger.id}>
								{d.sensorsList !== undefined ? (
									<TankLevelDataIndustryPanel
										sensor={d.sensorsList}
										datalogger={d.datalogger}
									/>
								) : null}
							</div>
						))}
					</div>
				</div>
			) : (
				<NoDeviceConnectedMessage />
			)}
		</div>
	);
}
