import React, { useEffect, useState, useMemo } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";

import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { paginationComponentOptions } from "../../../data-table-custom/dataTableCustom";
import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import ErrorMessage from "../../messages-panel/error-message";

import SelectDataButton from "../../date-selector";

import moment from "moment";

export default function DailyDataTablePanel({ gateway }) {
  const { access_token } = AuthService.getCurrentUser();
  const [periodo1, setPeriodo1] = useState("");
  const [periodo2, setPeriodo2] = useState("");
  const [data, setData] = useState([]);

  let api_request;
  if (periodo1 === "") {
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek = moment(lastWeek).format("YYYY-MM-DD");
    api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
    setPeriodo1(new Date(lastWeek));
  } else {
    let data1 = moment(periodo1).format("YYYY-MM-DD");
    if (periodo2 !== "") {
      let data2 = moment(periodo2).format("YYYY-MM-DD");
      api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
    } else {
      api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
    }
  }

  let getData = useGet(api_request, access_token);
  console.log(getData.state);

  useEffect(() => {
    if (!getData.state.loading && !getData.state.error) {
      // Remove dados duplicados
      let unique = getData.state.data
        .map((e) => moment(e["data"]).format("YYYY-MM-DD"))
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => getData.state.data[obj])
        .map((e) => getData.state.data[e]);

      let convertData = unique.map((d) => {
        return {
          Data: d.data,
          Tmax: d.Tmax ? +d.Tmax : "",
          Tmed: d.Tmed ? +d.Tmed : "",
          Tmin: d.Tmin ? +d.Tmin : "",
          URmax: d.URmax ? +d.URmax : "",
          URmed: d.URmed ? +d.URmed : "",
          URmin: d.URmin ? +d.URmin : "",
          Vmed: d.Vmed ? +d.Vmed : "",
          RSmed: d.RSmed ? +d.RSmed : "",
          Pacc: d.Pacc ? +d.Pacc : "",
          Eto: d.Eto ? +d.Eto : "",
          Etc: d.Etc ? +d.Etc : "",
        };
      });

      setData(convertData);
    }
  }, [getData.state.loading, getData.state.error, getData.state.data]);

  console.log(data);

  const columns = [
    {
      name: "Data",
      selector: (row) => moment(row.Data).format("DD-MM-YY"),
      sortable: true,
    },
    {
      name: "Tmax (ºC)",
      selector: (row) =>
        typeof row.Tmax === "number" && !isNaN(row.Tmax)
          ? row.Tmax.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Tmax !== "number" || isNaN(item.Tmax)
      ),
    },
    {
      name: "Tmed (ºC)",
      selector: (row) =>
        typeof row.Tmed === "number" && !isNaN(row.Tmed)
          ? row.Tmed.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Tmed !== "number" || isNaN(item.Tmed)
      ),
    },
    {
      name: "Tmin (ºC)",
      selector: (row) =>
        typeof row.Tmin === "number" && !isNaN(row.Tmin)
          ? row.Tmin.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Tmin !== "number" || isNaN(item.Tmin)
      ),
    },
    {
      name: "URmax (%)",
      selector: (row) =>
        typeof row.URmax === "number" && !isNaN(row.URmax)
          ? row.URmax.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.URmax !== "number" || isNaN(item.URmax)
      ),
    },
    {
      name: "URmed (%)",
      selector: (row) =>
        typeof row.URmed === "number" && !isNaN(row.URmed)
          ? row.URmed.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.URmed !== "number" || isNaN(item.URmed)
      ),
    },
    {
      name: "URmin (%)",
      selector: (row) =>
        typeof row.URmin === "number" && !isNaN(row.URmin)
          ? row.URmin.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.URmin !== "number" || isNaN(item.URmin)
      ),
    },
    {
      name: "Vmed (km/h)",
      selector: (row) =>
        typeof row.Vmed === "number" && !isNaN(row.Vmed)
          ? row.Vmed.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Vmed !== "number" || isNaN(item.Vmed)
      ),
    },
    {
      name: "RSmed (W/m²)",
      selector: (row) =>
        typeof row.RSmed === "number" && !isNaN(row.RSmed)
          ? row.RSmed.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.RSmed !== "number" || isNaN(item.RSmed)
      ),
    },
    {
      name: "Pluviometria (mm)",
      selector: (row) =>
        typeof row.Pacc === "number" && !isNaN(row.Pacc)
          ? row.Pacc.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Pacc !== "number" || isNaN(item.Pacc)
      ),
    },
    {
      name: "ETo (mm)",
      selector: (row) =>
        typeof row.Eto === "number" && !isNaN(row.Eto)
          ? row.Eto.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Eto !== "number" || isNaN(item.Eto)
      ),
    },
    {
      name: "ETc (mm)",
      selector: (row) =>
        typeof row.Etc === "number" && !isNaN(row.Etc)
          ? row.Etc.toFixed(1)
          : "",
      omit: data.every(
        (item) => typeof item.Etc !== "number" || isNaN(item.Etc)
      ),
    },
  ];

  const Export = ({ onExport }) => (
    <ExportButton onExport={onExport} chartPanel={false} />
  );

  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(data, "dados-diarios")} />,
    [data]
  );

  return (
    <div
      className="big-panel-container"
      style={{ margin: "10px 50px 10px 50px" }}
    >
      <div className="panel-title-container">
        <em className="panel-title">Compilado dos Dados por Dia</em>
      </div>

      <div style={{ margin: "10px" }}>
        {getData.state.loading ? (
          <div className="panel-loading">
            <ReactLoading
              type="spinningBubbles"
              color="var(--main-color)"
              width="50px"
              height="50px"
            />
          </div>
        ) : getData.state.error ? (
          <ErrorMessage />
        ) : (
          <div style={{ margin: "0px 20px 0px 20px" }}>
            <SelectDataButton
              periodo1={periodo1}
              setPeriodo1={setPeriodo1}
              periodo2={periodo2}
              setPeriodo2={setPeriodo2}
              hourlyData={false}
            />
            <DataTable
              columns={columns}
              data={data}
              noDataComponent="Nenhum dado encontrado."
              fixedHeader
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped={true}
              actions={actionsMemo}
            />
          </div>
        )}
      </div>
    </div>
  );
}
