import React from "react";

import WaterFlowDataAnimalPanel from "../../panel/water-flow-data-animal-panel";
import WaterTemperatureDataAnimalPanel from "../../panel/water-temperature-data-animal-panel";

import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import { waterTempSensor, flowWaterAcc } from "../../../../sensors-type";

export default function WaterLoggingData({
	dataloggersList,
	sensors,
	sensorsType,
}) {
	// Obtem os sensores conectados ao coletor da estação WaterLogging
	let hydrometerSensor, sensorWaterTemp;

	if (dataloggersList.length > 0) {
		let getSensors = sensors.filter(
			(s) => dataloggersList[0].id === s.coletor
		);

		let tag_datalogger = dataloggersList[0].tag;

		let list = getSensors.map((s) => {
			let type = sensorsType.filter((el) => s.tipo === el.id);

			if (type.length > 0) {
				s.tipo = type[0].nome;
			}

			return {
				coletor: tag_datalogger,
				id_dispositivo: s.id_dispositivo,
				modelo: s.modelo,
				tag: s.tag,
				tipo: s.tipo,
			};
		});

		hydrometerSensor = list.filter((el) => flowWaterAcc === el.tipo);
		sensorWaterTemp = list.filter((el) => waterTempSensor === el.tipo);
	}

	return (
		<div className="dropdown-button-line">
			{dataloggersList.length === 0 &&
			(hydrometerSensor.length > 0 || sensorWaterTemp.lenght > 0) ? (
				<NoDeviceConnectedMessage />
			) : (
				<div className="show-data-container">
					{hydrometerSensor.length > 0 ? (
						<WaterFlowDataAnimalPanel
							sensor={hydrometerSensor[0]}
							panelType={"waterlogging"}
						/>
					) : null}
					{sensorWaterTemp ? (
						<WaterTemperatureDataAnimalPanel
							sensor={sensorWaterTemp[0]}
						/>
					) : null}
				</div>
			)}
		</div>
	);
}
