import noDataIcon from "../../../../assets/icons/no-data-available-icon.png";
import "../message-panels-styles.css";

export default function NoDataAvailableMessage() {
	return (
		<div className="message-container">
			<div className="message-content-container">
				<img src={noDataIcon} height={"100px"} width={"100px"} alt="" />
				<span className="message-text-style">
					Não há dados disponíveis no momento.
				</span>
			</div>
		</div>
	);
}
