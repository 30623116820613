import { useState } from "react";
import { useLocation } from "react-router-dom";

import SelectDropdown from "../../select-dropdown";
import { roleItems, systemTypeItems } from "../items";
import SaveDataButton from "../../data-table-custom/save-data-button";
import DeleteDataButton from "../../data-table-custom/delete-button";

export default function OrganizationEdit() {
	const { state } = useLocation();

	const [nome, setNome] = useState(`${state.organizationData.nome}`);
	const [papel, setPapel] = useState(
		roleItems.find((d) => d.nome === state.organizationData.nivel)
	);
	const [representante, setRepresentante] = useState(
		state.organizationsNameList.find(
			(d) => d.id === +state.organizationData.representante
		)
	);

	const [tipoSistema, setTipoSistema] = useState(
		systemTypeItems.find(
			(d) => d.id === state.organizationData.tipo_sistema
		)
	);

	return (
		<div className="page">
			<div className="admin-container">
				<span className="admin-title">ID</span>
				<input
					className="admin-input-disabled"
					type="text"
					name="id"
					value={`${state.organizationData.id}`}
					readOnly
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Nome</span>
				<input
					className="admin-input"
					type="text"
					name="nome"
					value={nome}
					onChange={(e) => setNome(e.target.value)}
				/>
			</div>
			<div className="admin-container">
				<span className="admin-title">Papel</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={roleItems}
					selectedValue={papel}
					setSelectedValue={setPapel}
					width={"305px"}
				/>
			</div>
			{papel &&
			(papel.nome === "Somente Visualização" ||
				papel.nome === "Cliente") ? (
				<div className="admin-container">
					<span className="admin-title">Representante</span>
					<SelectDropdown
						placeHolder="Selecionar..."
						options={state.organizationsNameList}
						selectedValue={representante}
						setSelectedValue={setRepresentante}
						width={"305px"}
					/>
				</div>
			) : null}
			<div className="admin-container">
				<span className="admin-title">Tipo de Sistema</span>
				<SelectDropdown
					placeHolder="Selecionar..."
					options={systemTypeItems}
					selectedValue={tipoSistema}
					setSelectedValue={setTipoSistema}
					width={"305px"}
				/>
			</div>
			<div className="footer">
				<SaveDataButton
					data={{
						id: `${state.organizationData.id}`,
						nome: `${nome}`,
						representante: representante ? representante.id : null,
						nivel: `${papel ? papel.nome : ""}`,
						tipo_sistema: tipoSistema ? tipoSistema.id : null,
					}}
					action={"put"}
					endpoint={`/organizacao_api/${state.organizationData.id}`}
					returnTo={"/organizations"}
				/>
				<DeleteDataButton
					endpoint={`/organizacao_api/${state.organizationData.id}`}
					returnTo={"/organizations"}
				/>
			</div>
		</div>
	);
}
