import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import SelectDataButton from "../../dashboard/date-selector";
import NoDataAvailableMessage from "../../dashboard/messages-panel/no-data-available-message";
import { LineChart1Element, LineChart2Elements } from "../../dashboard/charts";

import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import ExportButton from "../../data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { BiDotsHorizontal } from "react-icons/bi";

export default function DeviceStatusChartPanel({
  setOpenPanel,
  deviceData,
  dataType,
  device,
}) {
  const { access_token } = AuthService.getCurrentUser();
  const [periodo1, setPeriodo1] = useState("");
  const [periodo2, setPeriodo2] = useState("");
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [unit, setUnit] = useState("");
  const [label, setLabel] = useState("");
  const [fileName, setFileName] = useState("");

  let api_request;
  if (periodo1 === "") {
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 3);
    lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");

    if (device === "datalogger")
      api_request = `evento_consulta?tipo_mensagem=1&coletor=${deviceData.id_dispositivo}&data:>=${lastWeek}`;
    else
      api_request = `evento_consulta?tipo_mensagem=1&sensor=${deviceData.id_dispositivo}&data:>=${lastWeek}`;
    setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
  } else {
    let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

    if (periodo2 !== "") {
      let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
      if (device === "datalogger")
        api_request = `evento_consulta?tipo_mensagem=1&coletor=${deviceData.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
      else
        api_request = `evento_consulta?tipo_mensagem=1&sensor=${deviceData.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
    } else {
      if (device === "datalogger")
        api_request = `evento_consulta?tipo_mensagem=1&coletor=${deviceData.id_dispositivo}&data:>=${data1}`;
      else
        api_request = `evento_consulta?tipo_mensagem=1&sensor=${deviceData.id_dispositivo}&data:>=${data1}`;
    }
  }

  let getData = useGet(api_request, access_token);

  useEffect(() => {
    let dataArray = [];
    let exportDataAux = [];
    if (!getData.state.loading && getData.state.data.length > 0) {
      dataArray = getData.state.data.map((d) => {
        if (dataType === "Bateria") {
          setUnit("V");
          setFileName(`grafico-bateria-col-${deviceData.tag}`);

          exportDataAux.push({
            id: d.id,
            data: moment.unix(d.timestamp / 1000).format("DD/MM/YY HH:mm"),
            bateria: +d.batStt,
          });

          return {
            timestamp: moment
              .unix(d.timestamp / 1000)
              .format("YYYY-MM-DDTHH:mm:ss"),
            value: +d.batStt,
          };
        } else if (dataType === "RSSI") {
          setUnit("dBm");
          setLabel("RSSI");
          setFileName(`grafico-rssi-col-${deviceData.tag}`);

          exportDataAux.push({
            id: d.id,
            data: moment.unix(d.timestamp / 1000).format("DD/MM/YY HH:mm"),
            value1: +d.rssiDw,
            value2: +d.rssiUp,
          });

          return {
            timestamp: moment
              .unix(d.timestamp / 1000)
              .format("YYYY-MM-DDTHH:mm:ss"),
            value1: +d.rssiDw,
            value2: +d.rssiUp,
          };
        } else if (dataType === "SNR") {
          setUnit("dB");
          setLabel("SNR");
          setFileName(`grafico-snr-col-${deviceData.tag}`);

          exportDataAux.push({
            id: d.id,
            data: moment.unix(d.timestamp / 1000).format("DD/MM/YY HH:mm"),
            value1: +d.snrDw,
            value2: +d.snrUp,
          });

          return {
            timestamp: moment
              .unix(d.timestamp / 1000)
              .format("YYYY-MM-DDTHH:mm:ss"),
            value1: +d.snrDw,
            value2: +d.snrUp,
          };
        } else {
          setFileName(`grafico-${deviceData.tag}`);

          exportDataAux.push({
            id: d.id,
            data: moment.unix(d.timestamp / 1000).format("DD/MM/YY HH:mm"),
            value: +d.valor_sensor,
          });

          return {
            timestamp: moment
              .unix(d.timestamp / 1000)
              .format("YYYY-MM-DDTHH:mm:ss"),
            value: +d.valor_sensor,
          };
        }
      });
      exportDataAux = exportDataAux.map((objeto) => {
        const { id: propriedadeRemovida, ...restoDoObjeto } = objeto;
        return restoDoObjeto;
      });
      setExportData(exportDataAux);
      setData(dataArray);
    } else {
      setExportData([]);
      setData([]);
    }
  }, [dataType, deviceData.tag, getData.state.loading, getData.state.data]);

  const Export = ({ onExport }) => (
    <ExportButton onExport={onExport} chartPanel={true} />
  );

  return (
    <div>
      <div className="overlay" onClick={() => setOpenPanel(false)}></div>
      <div className="panel-chart-container">
        <button
          className="close-panel-button"
          onClick={() => setOpenPanel(false)}
        >
          <AiOutlineCloseCircle size={"25px"} />
        </button>

        <span className="device-status-panel-title">Gráfico de {dataType}</span>

        <div>
          {getData.state.loading ? (
            <div className="panel-loading">
              <ReactLoading
                type="spinningBubbles"
                color="var(--main-color)"
                width="50px"
                height="50px"
              />
            </div>
          ) : (
            <div>
              <SelectDataButton
                periodo1={periodo1}
                setPeriodo1={setPeriodo1}
                periodo2={periodo2}
                setPeriodo2={setPeriodo2}
                hourlyData={true}
              />
              {data.length > 0 ? (
                <div>
                  {dataType !== "SNR" && dataType !== "RSSI" ? (
                    <LineChart1Element
                      data={data}
                      unit={unit}
                      color="#003049"
                      fileName={fileName}
                    />
                  ) : (
                    <LineChart2Elements
                      data={data}
                      unit={unit}
                      name1={`${label} Down`}
                      name2={`${label} Up`}
                      color1={"#d62828"}
                      color2={"#003049"}
                      fileName={fileName}
                    />
                  )}
                </div>
              ) : (
                <NoDataAvailableMessage />
              )}
              <div className="panel-items">
                <BiDotsHorizontal />
                <span className="panel-items-title">
                  Exportar dados do Gráfico
                </span>
                <div className="right-container">
                  <Export
                    onExport={() =>
                      downloadCSV(
                        exportData,
                        `dados-${dataType}`
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
