import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import AddItemButton from "../../data-table-custom/add-item-button";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function CultivationTypeList() {
	const { access_token } = AuthService.getCurrentUser();
	let cultivationsList = useGet("cultura_api", access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!cultivationsList.state.loading && !cultivationsList.state.error) {
			setData(cultivationsList.state.data);
			setRecords(data);
		}
	}, [
		cultivationsList.state.loading,
		cultivationsList.state.error,
		cultivationsList.state.data,
		data,
	]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			cell: (row) => (
				<AddItemButton
					data={row}
					text={"Fases Fenológicas"}
					goTo={`/cultivations-list/${row.id}/phenological-stages`}
				/>
			),
		},
	];

	const openCultivationsDetail = (cultivationData) => {
		Navigate(`/cultivations-list/${cultivationData.id}`, {
			state: {
				cultivationData,
			},
		});
	};

	return (
		<div className="page">
			{cultivationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading type="bubbles" color="var(--main-color)" width="50px" height="50px" />
				</div>
			) : cultivationsList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar table={"cultivationTypes"} setRecords={setRecords} data={data} />
						<AddButton goTo={"/cultivations-list/add-new-cultivation"} />
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Cultura encontrada."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(cultivationData) => openCultivationsDetail(cultivationData)}
					/>
				</div>
			)}
		</div>
	);
}
