import { useNavigate } from "react-router-dom";
import "./dataTableCustom.css";
import { BsPlusCircle } from "react-icons/bs";

export default function AddButton({ goTo, list }) {
	const Navigate = useNavigate();
	const handleAddNewItemPage = () => {
		Navigate(goTo, {
			state: {
				list,
			},
		});
	};

	return (
		<div>
			<button
				className="data-table-button-custom"
				onClick={handleAddNewItemPage}
			>
				<BsPlusCircle size={"16px"} className="icon-style" />
				Novo
			</button>
		</div>
	);
}
