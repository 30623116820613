import React, { useState, useEffect } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import PanelHeader from "../../panel-header";
import DashboardButtons from "../../dashboard-buttons";
import WellBeingData from "../wellbeing-data";
import WaterLogging from "../waterlogging-data";
import SiloLevelData from "../silo-level-data";

import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import ErrorMessage from "../../messages-panel/error-message";

export default function ShedButtonData({ gatewayID, sensorsType }) {
	let { access_token } = AuthService.getCurrentUser();
	const [dataloggerList, setDataloggerList] = useState([]);
	const [sensorsList, setSensorsList] = useState([]);
	const [listaGalpoes, setListaGalpoes] = useState([]); // Lista para aparecer no DashboardHeader
	const [galpao, setGalpao] = useState(null); // Galpao escolhido no DashboardHeader

	// Tipos de Botão:
	// 1 - wellbeing (WB)
	// 2 - waterlogging (WL)
	// 3 - nivel-silo (SL)
	const [button1, setButton1] = useState(false);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);

	let getColetores = useGet(
		`coletor_consulta?coletor_gateway=${gatewayID}`,
		access_token
	);

	let getSensors = useGet(
		`sensor_consulta?sensor_concentrador=${gatewayID}`,
		access_token
	);

	useEffect(() => {
		if (
			!getColetores.state.loading &&
			!getColetores.state.error &&
			!getSensors.state.loading &&
			!getSensors.state.error
		) {
			let count = new Set(); // flag para ajudar a saber quantos galpoes monitorados a Organizacao possui

			getColetores.state.data.forEach((s) => {
				if (s.tag.substring(0, 1) === "G") {
					const getNumber = s.tag.substring(1, 3);

					count.add(getNumber);
				}
			});

			// Convertendo o Set para um array usando o operador de espalhamento
			count = [...count];

			let galpoes = count.map((i) => {
				return {
					id: parseInt(i),
					nome: "Galpão " + i,
				};
			});

			setListaGalpoes(galpoes.sort((a, b) => a.id - b.id));
			setSensorsList(getSensors.state.data);
		}
	}, [
		getColetores.state.loading,
		getColetores.state.error,
		getColetores.state.data,
		getSensors.state.loading,
		getSensors.state.error,
		getSensors.state.data,
		galpao,
	]);

	useEffect(() => {
		if (galpao) {
			let getDLs = getColetores.state.data.filter(
				(dl) => parseInt(dl.tag.substring(1, 3)) === galpao.id
			);

			setDataloggerList(getDLs);
		}
	}, [galpao, getColetores.state.data]);

	return (
		<div className="dropdown-button-line">
			{getColetores.state.loading && getSensors.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getColetores.state.error || getSensors.state.error ? (
				<ErrorMessage />
			) : listaGalpoes.length > 0 ? (
				<div style={{ padding: "0px 20px 10px 20px" }}>
					<PanelHeader
						componentsList={listaGalpoes}
						chosenComponent={galpao}
						setChosenComponent={setGalpao}
						titleHeader={"Dados referentes ao Galpão"}
						imageType={"shed"}
					/>

					<div className="dropdown-button-item-container">
						<DashboardButtons
							buttonType={"wellbeing"}
							buttonNumber={button1}
							setButtonNumber={setButton1}
							buttonTitle={"WellBeing"}
							reference={galpao}
						/>
						{button1 ? (
							<WellBeingData
								dataloggersList={dataloggerList.filter(
									(dl) =>
										dl.tag.substring(3, 5).toUpperCase() ===
										"WB"
								)}
								sensors={sensorsList}
								sensorsType={sensorsType}
							/>
						) : null}
					</div>
					<div className="dropdown-button-item-container">
						<DashboardButtons
							buttonType={"waterlogging"}
							buttonNumber={button2}
							setButtonNumber={setButton2}
							buttonTitle={"WaterLogging"}
							reference={galpao}
						/>
						{button2 ? (
							<WaterLogging
								dataloggersList={dataloggerList.filter(
									(dl) =>
										dl.tag.substring(3, 5).toUpperCase() ===
										"WL"
								)}
								sensors={sensorsList}
								sensorsType={sensorsType}
							/>
						) : null}
					</div>
					<div className="dropdown-button-item-container">
						<DashboardButtons
							buttonType={"nivel-silo"}
							buttonNumber={button3}
							setButtonNumber={setButton3}
							buttonTitle={"Nível de Silo"}
							reference={galpao}
						/>
						{button3 ? (
							<SiloLevelData
								dataloggersList={dataloggerList.filter(
									(dl) =>
										dl.tag.substring(3, 5).toUpperCase() ===
										"SL"
								)}
								sensors={sensorsList}
								sensorsType={sensorsType}
							/>
						) : null}
					</div>
				</div>
			) : (
				<NoDeviceConnectedMessage />
			)}
		</div>
	);
}
