import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import requestConfig from "../../reducers/request_config";

import AlertComponent from "../alert-component/alertComponent";

import "./dataTableCustom.css";
import { FiSave } from "react-icons/fi";

export default function SaveDataButton({
  data,
  action,
  endpoint,
  returnTo,
  validateFields,
}) {
  const Navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleSaveData = async (e) => {
    e.preventDefault();

    if (typeof validateFields === 'function' && !validateFields()) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setIsloading(true);

    try {
      const resp = await axios.request(
        requestConfig({
          method: action,
          endpoint: endpoint,
          access_token: user.access_token,
          data: data,
        })
      );
      setResponse(resp);
      setShowAlert(true);
    } catch (e) {
      setErrorStatus(true);
      setError(e.message);
      setShowAlert(true);
    } finally {
      setIsloading(false);
    }

    setTimeout(() => {
      setShowAlert(false);
      Navigate(returnTo);
    }, 1000);
  };

  return (
    <div>
      <button className="data-table-button-custom" onClick={handleSaveData}>
        <FiSave size={"16px"} className="icon-style" />
        Salvar
      </button>
      {isLoading ? (
        <AlertComponent message={"Carregando..."} code={"LOADING"} />
      ) : null}
      {errorStatus && showAlert ? (
        <AlertComponent message={error} code={"ERROR"} />
      ) : null}
      {action === "post" && response.status === 201 ? (
        <AlertComponent
          message={"Novo item adicionado com sucesso!"}
          code={"SUCCESS"}
        />
      ) : null}
      {action === "put" && response.status === 200 && showAlert ? (
        <AlertComponent
          message={"Atualização feita com sucesso!"}
          code={"SUCCESS"}
        />
      ) : null}
    </div>
  );
}
